const roads = [
{ name: '7. juni-plassen', completed: false },
{ name: 'Abbediengen terrasse', completed: false },
{ name: 'Abbediengveien', completed: false },
{ name: 'Abbedikollen', completed: false },
{ name: 'Abbedisvingen', completed: false },
{ name: 'Abildsøfaret', completed: false },
{ name: 'Abildsøveien', completed: false },
{ name: 'Adam Hiorths vei', completed: true },
{ name: 'Admiral Børresens vei', completed: false },
{ name: 'Adolf Hedins vei', completed: true },
{ name: 'Adventveien', completed: false },
{ name: 'Advokat Dehlis plass', completed: false },
{ name: 'Agathe Grøndahls gate', completed: true },
{ name: 'Agdergata', completed: false },
{ name: 'Agmund Bolts vei', completed: true },
{ name: 'Agnes Thorsens plass', completed: false },
{ name: 'Agronomveien', completed: false },
{ name: 'Akebakkeskogen', completed: false },
{ name: 'Akerlia', completed: false },
{ name: 'Akersbakken', completed: false },
{ name: 'Akersborg terrasse', completed: false },
{ name: 'Akersgata', completed: false },
{ name: 'Akershusstranda', completed: false },
{ name: 'Akersveien', completed: false },
{ name: 'Akevittsvingen', completed: false },
{ name: 'Albert Nordengens plass', completed: false },
{ name: 'Alexander Kiellands plass', completed: false },
{ name: 'Alf Bjerckes vei', completed: false },
{ name: 'Alfaset 1. industrivei', completed: false },
{ name: 'Alfaset 3. industrivei', completed: false },
{ name: 'Alfasetveien', completed: false },
{ name: 'Alfred Groruds vei', completed: false },
{ name: 'Almeveien', completed: false },
{ name: 'Alnabruveien', completed: false },
{ name: 'Alnafetgata', completed: false },
{ name: 'Alnagata', completed: false },
{ name: 'Alnaparkveien', completed: false },
{ name: 'Alundamveien', completed: false },
{ name: 'Alunsjøveien', completed: false },
{ name: 'Alvheimveien', completed: false },
{ name: 'Amagerveien', completed: false },
{ name: 'Amaldus Nielsens plass', completed: false },
{ name: 'Ammerudgrenda', completed: false },
{ name: 'Ammerudhellinga', completed: false },
{ name: 'Ammerudveien', completed: false },
{ name: 'Amtmann Furus plass', completed: false },
{ name: 'Amtmann Meinichs gate', completed: true },
{ name: 'Amund Hellands vei', completed: false },
{ name: 'Andreas Kremmers vei', completed: false },
{ name: 'Ankertorget', completed: false },
{ name: 'Ankerveien', completed: false },
{ name: 'Anna Pleyms vei', completed: false },
{ name: 'Anna Rogstads vei', completed: false },
{ name: 'Anna Sethnes gate', completed: true },
{ name: 'Anne Brannfjelds vei', completed: false },
{ name: 'Anne Kures sti', completed: false },
{ name: 'Anne Maries vei', completed: false },
{ name: 'Annette Thommessens plass', completed: false },
{ name: 'Ansgar Sørlies vei', completed: true },
{ name: 'Antenneveien', completed: false },
{ name: 'Anton Schjøths gate', completed: false },
{ name: 'Anton Tschudis vei', completed: false },
{ name: 'Apalløkkveien', completed: false },
{ name: 'Apalveien', completed: false },
{ name: 'Apotekergata', completed: false },
{ name: 'Arbeidergata', completed: false },
{ name: 'Arbeidersamfunnets plass', completed: false },
{ name: 'Arbins gate', completed: false },
{ name: 'Arbos gate', completed: false },
{ name: 'Arctanders gate', completed: false },
{ name: 'Arendalsgata', completed: false },
{ name: 'Arilds vei', completed: false },
{ name: 'Arildsvingen', completed: false },
{ name: 'Arkitekt Rivertz plass', completed: false },
{ name: 'Arkitekt Steckmests vei', completed: false },
{ name: 'Armauer Hansens gate', completed: false },
{ name: 'Arnebråtveien', completed: false },
{ name: 'Arne Garborgs plass', completed: false },
{ name: 'Arne Garborgs vei', completed: false },
{ name: 'Arne Gjestis plass', completed: false },
{ name: 'Arnes vei', completed: false },
{ name: 'Arnljot Gellines vei', completed: true },
{ name: 'Arno Bergs plass', completed: false },
{ name: 'Arnstein Arnebergs vei', completed: false },
{ name: 'Arnulf Øverlands vei', completed: false },
{ name: 'Arthur Nordlies vei', completed: false },
{ name: 'Arups gate', completed: false },
{ name: 'Arvesetveien', completed: false },
{ name: 'Arvid Storsveens plass', completed: false },
{ name: 'Asalveien', completed: false },
{ name: 'Asbjørnsens vei', completed: false },
{ name: 'Aschehougs vei', completed: false },
{ name: 'Askekroken', completed: false },
{ name: 'Askeladdveien', completed: false },
{ name: 'Askergata', completed: true },
{ name: 'Askeveien', completed: false },
{ name: 'Aslakveien', completed: false },
{ name: 'Aslaug Vaas veg', completed: false },
{ name: 'Aspehaugveien', completed: false },
{ name: 'Asperudlia', completed: false },
{ name: 'Asperudtoppen', completed: false },
{ name: 'Asperudveien', completed: false },
{ name: 'Asperudåsen', completed: false },
{ name: 'Aspestien', completed: false },
{ name: 'Astrids vei', completed: false },
{ name: 'Aud Schønemanns vei', completed: true },
{ name: 'August Cappelens gate', completed: false },
{ name: 'Aurskoggata', completed: false },
{ name: 'Austliveien', completed: false },
{ name: 'Avlangruds vei', completed: false },
{ name: 'Avstikkeren', completed: false },
{ name: 'Axel Brinchs vei', completed: false },
{ name: 'Axel Flinders vei', completed: false },
{ name: 'Axel Huitfeldts vei', completed: false },
{ name: 'Badebakken', completed: false },
{ name: 'Badstugata', completed: false },
{ name: 'Baglerfaret', completed: false },
{ name: 'Baglerstredet', completed: false },
{ name: 'Bakkehaugveien', completed: false },
{ name: 'Bakkerudveien', completed: false },
{ name: 'Bakketoppen', completed: false },
{ name: 'Bakkeveien', completed: false },
{ name: 'Bakveien', completed: false },
{ name: 'Balchens gate', completed: false },
{ name: 'Balders gate', completed: false },
{ name: 'Ballplassveien', completed: false },
{ name: 'Bamseveien', completed: false },
{ name: 'Baneveien', completed: false },
{ name: 'Bankplassen', completed: false },
{ name: 'Banksjef Frølichs gate', completed: false },
{ name: 'Barliveien', completed: false },
{ name: 'Barnehjemsveien', completed: false },
{ name: 'Barnålveien', completed: false },
{ name: 'Bauneveien', completed: false },
{ name: 'Beddingen', completed: false },
{ name: 'Bedriftsveien', completed: false },
{ name: 'Behrens gate', completed: false },
{ name: 'Beiteveien', completed: false },
{ name: 'Bekkefaret', completed: false },
{ name: 'Bekkelagsterrassen', completed: false },
{ name: 'Bekkelagsveien', completed: false },
{ name: 'Bekkelibakken', completed: false },
{ name: 'Bekkelikroken', completed: false },
{ name: 'Bekkelistubben', completed: false },
{ name: 'Bekkeliveien', completed: false },
{ name: 'Bekkenstenveien', completed: false },
{ name: 'Bekkeveien', completed: true },
{ name: 'Bekkevollveien', completed: false },
{ name: 'Belgerudveien', completed: false },
{ name: 'Benneches gate', completed: false },
{ name: 'Bentsebrugata', completed: true },
{ name: 'Bentsegata', completed: false },
{ name: 'Benveien', completed: false },
{ name: 'Bergendalsveien', completed: false },
{ name: 'Bergensgata', completed: false },
{ name: 'Bergensveien', completed: false },
{ name: 'Bergkrystallen', completed: false },
{ name: 'Bergljots vei', completed: true },
{ name: 'Berglyveien', completed: false },
{ name: 'Bergrådveien', completed: false },
{ name: 'Bergsalléen', completed: false },
{ name: 'Bergslia', completed: false },
{ name: 'Bergsliens gate', completed: false },
{ name: 'Bergstien', completed: false },
{ name: 'Bergtunveien', completed: false },
{ name: 'Bergverksgata', completed: false },
{ name: 'Bernhard Getz gate', completed: false },
{ name: 'Bernhard Herres vei', completed: false },
{ name: 'Bernhusveien', completed: false },
{ name: 'Bernt Ankers gate', completed: false },
{ name: 'Bernt Knudsens vei', completed: false },
{ name: 'Bertramjordet', completed: false },
{ name: 'Bertrand Narvesens vei', completed: true },
{ name: 'Bervens løkke', completed: false },
{ name: 'Bestemorstien', completed: false },
{ name: 'Bestum skolevei', completed: false },
{ name: 'Bestumstubben', completed: false },
{ name: 'Bestum tverrvei', completed: false },
{ name: 'Bestumveien', completed: false },
{ name: 'Bestumåsen', completed: false },
{ name: 'Betzy Kjelsbergs vei', completed: false },
{ name: 'Beverkollen', completed: false },
{ name: 'Beverveien', completed: false },
{ name: 'Bidenkaps gate', completed: false },
{ name: 'Biermanns gate', completed: false },
{ name: 'Bikuben', completed: false },
{ name: 'Bilittkroken', completed: false },
{ name: 'Binneveien', completed: false },
{ name: 'Birch-Reichenwalds gate', completed: true },
{ name: 'Birger Olivers vei', completed: false },
{ name: 'Birger Aaneruds vei', completed: false },
{ name: 'Birgitte Hammers vei', completed: false },
{ name: 'Birkelunden', completed: false },
{ name: 'Biskop Grimelunds vei', completed: false },
{ name: 'Biskop Gunnerus gate', completed: false },
{ name: 'Biskop Heuchs vei', completed: false },
{ name: 'Biskop Jens Nilssøns gate', completed: false },
{ name: 'Bislettgata', completed: false },
{ name: 'Bislett plass', completed: false },
{ name: 'Bisp Nikolas gate', completed: false },
{ name: 'Bispegata', completed: false },
{ name: 'Bispeluelia', completed: false },
{ name: 'Bjartveien', completed: false },
{ name: 'Bjerkastien', completed: false },
{ name: 'Bjerkealléen', completed: false },
{ name: 'Bjerkebakken', completed: false },
{ name: 'Bjerkefaret', completed: false },
{ name: 'Bjerkelia', completed: false },
{ name: 'Bjerkeliveien', completed: false },
{ name: 'Bjerkelundgata', completed: false },
{ name: 'Bjerregaards gate', completed: false },
{ name: 'Bjerringbakken', completed: false },
{ name: 'Bjølsengata', completed: false },
{ name: 'Bjørkehavna', completed: false },
{ name: 'Bjørn Bondes vei', completed: false },
{ name: 'Bjørndalsjordet', completed: false },
{ name: 'Bjørn Farmanns gate', completed: false },
{ name: 'Bjørn Lies vei', completed: false },
{ name: 'Bjørn Magnussons vei', completed: false },
{ name: 'Bjørn Stallares vei', completed: true },
{ name: 'Bjørnefaret', completed: false },
{ name: 'Bjørnehiet', completed: false },
{ name: 'Bjørnerabben', completed: false },
{ name: 'Bjørnerudveien', completed: false },
{ name: 'Bjørnheimveien', completed: false },
{ name: 'Bjørnslettstubben', completed: false },
{ name: 'Bjørnslettveien', completed: false },
{ name: 'Bjørnstadbakken', completed: false },
{ name: 'Bjørnstjerne Bjørnsons plass', completed: false },
{ name: 'Bjørnveien', completed: false },
{ name: 'Bjørnåsveien', completed: false },
{ name: 'Blakkens vei', completed: false },
{ name: 'Bleikøya', completed: false },
{ name: 'Blindernveien', completed: false },
{ name: 'Blokkaveien', completed: false },
{ name: 'Blytts gate', completed: false },
{ name: 'Blåbærsvingen', completed: false },
{ name: 'Blåsbortveien', completed: false },
{ name: 'Blåskjellveien', completed: false },
{ name: 'Bodøgata', completed: true },
{ name: 'Bogerudslyngen', completed: false },
{ name: 'Bogerudveien', completed: false },
{ name: 'Bogstadveien', completed: false },
{ name: 'Bolette brygge', completed: false },
{ name: 'Bolteløkka allé', completed: false },
{ name: 'Bomveien', completed: false },
{ name: 'Borgenbakken', completed: false },
{ name: 'Borgenveien', completed: false },
{ name: 'Borger Withs gate', completed: true },
{ name: 'Borgestadveien', completed: false },
{ name: 'Borggata', completed: false },
{ name: 'Brageveien', completed: false },
{ name: 'Brandts gate', completed: false },
{ name: 'Brannfjellveien', completed: false },
{ name: 'Brannvaktveien', completed: false },
{ name: 'Brantenborgveien', completed: false },
{ name: 'Brattbakken', completed: false },
{ name: 'Brattelia', completed: false },
{ name: 'Brattlistubben', completed: false },
{ name: 'Brattoddveien', completed: false },
{ name: 'Brattvollveien', completed: false },
{ name: 'Bredes vei', completed: false },
{ name: 'Bredo Stabells vei', completed: false },
{ name: 'Bredtvetveien', completed: false },
{ name: 'Bregneveien', completed: false },
{ name: 'Breidablikkveien', completed: false },
{ name: 'Breiens vei', completed: false },
{ name: 'Breigata', completed: false },
{ name: 'Breisjåveien', completed: true },
{ name: 'Breivollveien', completed: false },
{ name: 'Brekkelia', completed: false },
{ name: 'Brekkeveien', completed: false },
{ name: 'Brennagrenda', completed: false },
{ name: 'Brennastubben', completed: false },
{ name: 'Brennaveien', completed: false },
{ name: 'Brenneriveien', completed: false },
{ name: 'Brettevilles gate', completed: true },
{ name: 'Brinken', completed: false },
{ name: 'Briskebyveien', completed: false },
{ name: 'Briskeveien', completed: false },
{ name: 'Brobekkveien', completed: false },
{ name: 'Brochmanns gate', completed: false },
{ name: 'Brolandsveien', completed: false },
{ name: 'Brubakkveien', completed: false },
{ name: 'Brugata', completed: false },
{ name: 'Brunas vei', completed: false },
{ name: 'Bryggegangen', completed: false },
{ name: 'Bryggegata', completed: false },
{ name: 'Bryggetorget', completed: false },
{ name: 'Brynjeveien', completed: false },
{ name: 'Brynjulf Bulls plass', completed: false },
{ name: 'Brynsalléen', completed: true },
{ name: 'Brynsengfaret', completed: true },
{ name: 'Brynsengveien', completed: true },
{ name: 'Brynshøgda', completed: true },
{ name: 'Brynsveien', completed: true },
{ name: 'Brønnveien', completed: false },
{ name: 'Bråtenalléen', completed: false },
{ name: 'Bueveien', completed: false },
{ name: 'Bukken Bruses vei', completed: false },
{ name: 'Bygdøy allé', completed: false },
{ name: 'Bygdøy kapellvei', completed: false },
{ name: 'Bygdøy terrasse', completed: false },
{ name: 'Bygdøylund', completed: false },
{ name: 'Bygdøynesveien', completed: false },
{ name: 'Bygdøyveien', completed: false },
{ name: 'Byggveien', completed: false },
{ name: 'Bülow Hanssens plass', completed: false },
{ name: 'Byveien', completed: false },
{ name: 'Bærumsveien', completed: false },
{ name: 'Bøgata', completed: false },
{ name: 'Bøkkerveien', completed: true },
{ name: 'Bølerbakken', completed: false },
{ name: 'Bølerlia', completed: false },
{ name: 'Bølerskogen', completed: false },
{ name: 'Bølerskrenten', completed: false },
{ name: 'Bølersletta', completed: false },
{ name: 'Bølersvingen', completed: false },
{ name: 'Bølerveien', completed: false },
{ name: 'Børrestuveien', completed: false },
{ name: 'Børsteveien', completed: true },
{ name: 'Båhusveien', completed: true },
{ name: 'Bålveien', completed: false },
{ name: 'Bånkallstubben', completed: false },
{ name: 'Båntjernveien', completed: false },
{ name: 'Bård Skolemesters vei', completed: false },
{ name: 'C.A. Pihls gate', completed: false },
{ name: 'C.A. Torstensens vei', completed: false },
{ name: 'Calmeyers gate', completed: false },
{ name: 'Camilla Colletts vei', completed: false },
{ name: 'Cappelens vei', completed: false },
{ name: 'Carl Berners plass', completed: false },
{ name: 'Carl Grøndahls vei', completed: false },
{ name: 'Carl Jeppesens gate', completed: true },
{ name: 'Carl Kjelsens vei', completed: false },
{ name: 'Caspar Storms vei', completed: false },
{ name: 'Casparis gate', completed: false },
{ name: 'Cathinka Guldbergs plass', completed: false },
{ name: 'Cato Guldbergs vei', completed: false },
{ name: 'Cecilie Thoresens vei', completed: false },
{ name: 'Charlotte Andersens vei', completed: false },
{ name: 'Christian Benneches vei', completed: false },
{ name: 'Christian Frederiks plass', completed: false },
{ name: 'Christian Frederiks vei', completed: false },
{ name: 'Christian Krohgs gate', completed: false },
{ name: 'Christian Michelsens gate', completed: false },
{ name: 'Christian Schous vei', completed: false },
{ name: 'Christiania Torv', completed: false },
{ name: 'Christies gate', completed: false },
{ name: 'Christoffer Hellums vei', completed: false },
{ name: 'Christopher Hansteens vei', completed: false },
{ name: 'Christophers vei', completed: false },
{ name: 'C.J. Hambros plass', completed: false },
{ name: 'Claus Borchs vei', completed: false },
{ name: 'Claus Riis gate', completed: false },
{ name: 'Clemens gate', completed: false },
{ name: 'Colbjørnsens gate', completed: false },
{ name: 'Colletts gate', completed: false },
{ name: 'Conrad Hemsens vei', completed: false },
{ name: 'Conradis gate', completed: false },
{ name: 'Cort Adelers gate', completed: false },
{ name: 'Dag Hammarskjølds vei', completed: true },
{ name: 'Dagalikroken', completed: false },
{ name: 'Dagaliveien', completed: false },
{ name: 'Dagmarbakken', completed: false },
{ name: 'Dalastien', completed: false },
{ name: 'Dalbakkveien', completed: false },
{ name: 'Dalehaugen', completed: false },
{ name: 'Dalerudveien', completed: false },
{ name: 'Dalheimveien', completed: false },
{ name: 'Dalsbergstien', completed: false },
{ name: 'Dalskroken', completed: false },
{ name: 'Dalsløkka', completed: false },
{ name: 'Dalsnaret', completed: false },
{ name: 'Dalsroa', completed: false },
{ name: 'Dalssvingen', completed: false },
{ name: 'Dalsveien', completed: false },
{ name: 'Dalsåsen', completed: false },
{ name: 'Damfaret', completed: false },
{ name: 'Damkroken', completed: false },
{ name: 'Damlihaugen', completed: false },
{ name: 'Damliveien', completed: false },
{ name: 'Dammanns vei', completed: false },
{ name: 'Damplassen', completed: false },
{ name: 'Damstredet', completed: false },
{ name: 'Damveien', completed: false },
{ name: 'Daniel Walstads vei', completed: false },
{ name: 'Danmarks gate', completed: false },
{ name: 'Dannevigsveien', completed: false },
{ name: 'Darre-Jenssens plass', completed: false },
{ name: 'Darres gate', completed: false },
{ name: 'Deichmans gate', completed: false },
{ name: 'Delebekken', completed: false },
{ name: 'Diakonveien', completed: false },
{ name: 'Diriks gate', completed: false },
{ name: 'Disengrenda', completed: false },
{ name: 'Disenveien', completed: false },
{ name: 'Dokkveien', completed: false },
{ name: 'Doktor Baches vei', completed: false },
{ name: 'Doktor Holms vei', completed: false },
{ name: 'Doktor Kloumanns vei', completed: false },
{ name: 'Doktor Londons vei', completed: false },
{ name: 'Doktor Rustads vei', completed: false },
{ name: 'Doktor Smiths vei', completed: false },
{ name: 'Dops gate', completed: false },
{ name: 'Dorthes vei', completed: false },
{ name: 'Dovregata', completed: false },
{ name: 'Dovresvingen', completed: false },
{ name: 'Dr. Dedichens vei', completed: false },
{ name: 'Dragonstien', completed: false },
{ name: 'Drammensveien', completed: false },
{ name: 'Drivhusveien', completed: false },
{ name: 'Dronning Astrids gate', completed: false },
{ name: 'Dronning Blancas vei', completed: false },
{ name: 'Dronning Eufemias gate', completed: true },
{ name: 'Dronning Ingrids plass', completed: true },
{ name: 'Dronning Mauds gate', completed: false },
{ name: 'Dronning Margretes vei', completed: true },
{ name: 'Dronningen', completed: false },
{ name: 'Dronningens gate', completed: false },
{ name: 'Dronninghavnveien', completed: false },
{ name: 'Drøbakgata', completed: false },
{ name: 'Dråga', completed: false },
{ name: 'Duehaugveien', completed: false },
{ name: 'Duggveien', completed: false },
{ name: 'Dugnadsveien', completed: false },
{ name: 'Dunkers gate', completed: false },
{ name: 'Dybwads gate', completed: false },
{ name: 'Dyna brygge', completed: false },
{ name: 'Dynekilgata', completed: true },
{ name: 'Dyretråkket', completed: false },
{ name: 'Dyrlandsveien', completed: false },
{ name: 'Dyvekes vei', completed: false },
{ name: 'Dælenenggata', completed: true },
{ name: 'Dølerudveien', completed: false },
{ name: 'Daas gate', completed: false },
{ name: 'Ebbells gate', completed: false },
{ name: 'Eckersbergs gate', completed: false },
{ name: 'Eddaveien', completed: false },
{ name: 'Edmund Neuperts gate', completed: true },
{ name: 'Edvard Griegs allé', completed: true },
{ name: 'Edvard Munchs vei', completed: false },
{ name: 'Edvard Storms gate', completed: false },
{ name: 'Eftasåsen', completed: false },
{ name: 'Egedes gate', completed: false },
{ name: 'Egertorget', completed: false },
{ name: 'Egnehjemveien', completed: false },
{ name: 'Egon Olsens allé', completed: false },
{ name: 'Eidsvolls plass', completed: false },
{ name: 'Eikelundveien', completed: false },
{ name: 'Eikenga', completed: true },
{ name: 'Eikeveien', completed: false },
{ name: 'Eilert Smiths vei', completed: false },
{ name: 'Eilert Sundts gate', completed: false },
{ name: 'Einar Gerhardsens plass', completed: false },
{ name: 'Einar Høigårds vei', completed: false },
{ name: 'Einar Skjæraasens vei', completed: false },
{ name: 'Einar Tambarskjelves plass', completed: false },
{ name: 'Einars vei', completed: true },
{ name: 'Eindrides vei', completed: true },
{ name: 'Einerveien', completed: false },
{ name: 'Eirik Raudes vei', completed: true },
{ name: 'Eiriks gate', completed: false },
{ name: 'Eivind Astrups gate', completed: false },
{ name: 'Ekebergstien', completed: false },
{ name: 'Ekebergveien', completed: false },
{ name: 'Ekelyveien', completed: false },
{ name: 'Ekornveien', completed: false },
{ name: 'Ekraveien', completed: false },
{ name: 'Elgtråkket', completed: false },
{ name: 'Elgveien', completed: false },
{ name: 'Elias Blix gate', completed: false },
{ name: 'Elisa Platous plass', completed: false },
{ name: 'Elisenbergveien', completed: false },
{ name: 'Ellefs vei', completed: false },
{ name: 'Ellen Gleditsch vei', completed: false },
{ name: 'Ellingsrudveien', completed: false },
{ name: 'Elmholt allé', completed: false },
{ name: 'Elmholtveien', completed: false },
{ name: 'Elsters gate', completed: false },
{ name: 'Eltonveien', completed: false },
{ name: 'Elvefaret', completed: false },
{ name: 'Elveliveien', completed: false },
{ name: 'Elverumgata', completed: false },
{ name: 'Emil Korsmos vei', completed: true },
{ name: 'Enebakkveien', completed: false },
{ name: 'Enebostubben', completed: false },
{ name: 'Eneboveien', completed: false },
{ name: 'Enerhauggata', completed: false },
{ name: 'Enerhaugkleiva', completed: false },
{ name: 'Enerhaugplassen', completed: false },
{ name: 'Engebrets vei', completed: false },
{ name: 'Engebråtveien', completed: false },
{ name: 'Engveien', completed: false },
{ name: 'Enoks vei', completed: false },
{ name: 'Ensjøsvingen', completed: true },
{ name: 'Ensjøveien', completed: true },
{ name: 'Erich Mogensøns vei', completed: false },
{ name: 'Erika Nissens gate', completed: true },
{ name: 'Erik Schias plass', completed: false },
{ name: 'Erlandstuveien', completed: false },
{ name: 'Erlends vei', completed: false },
{ name: 'Erling Michelsens vei', completed: false },
{ name: 'Erling Schiøtz vei', completed: false },
{ name: 'Erling Skjalgssons gate', completed: false },
{ name: 'Erlings gate', completed: false },
{ name: 'Essendrops gate', completed: false },
{ name: 'Eterveien', completed: false },
{ name: 'Etterstadgata', completed: false },
{ name: 'Etterstadkroken', completed: true },
{ name: 'Etterstadsletta', completed: false },
{ name: 'Eugene Hanssens gate', completed: false },
{ name: 'Eugenies gate', completed: false },
{ name: 'Europarådets plass', completed: false },
{ name: 'Eva Kolstads gate', completed: false },
{ name: 'Evald Ryghs gate', completed: false },
{ name: 'Evald Ryghs plass', completed: false },
{ name: 'Evens gate', completed: false },
{ name: 'Eventyrveien', completed: false },
{ name: 'Eystein Torkildsens vei', completed: false },
{ name: '', completed: false },
{ name: 'Fabrikkgata', completed: false },
{ name: 'Fabritiusalléen', completed: false },
{ name: 'Fagerborggata', completed: false },
{ name: 'Fagerheimgata', completed: true },
{ name: 'Fagerlia', completed: true },
{ name: 'Fagerlitrappa', completed: false },
{ name: 'Fagerliveien', completed: false },
{ name: 'Fagertunveien', completed: false },
{ name: 'Fahlstrøms terrasse', completed: false },
{ name: 'Falbes gate', completed: false },
{ name: 'Falck Ytters plass', completed: false },
{ name: 'Fallanveien', completed: false },
{ name: 'Falsens gate', completed: false },
{ name: 'Fastings gate', completed: false },
{ name: 'Fauchalds gate', completed: false },
{ name: 'Faunveien', completed: false },
{ name: 'Fayes gate', completed: false },
{ name: 'Fearnleys gate', completed: false },
{ name: 'Feddersens gate', completed: true },
{ name: 'Feltspatveien', completed: false },
{ name: 'Fernanda Nissens gate', completed: true },
{ name: 'Festningsplassen', completed: false },
{ name: 'Fetsundgata', completed: false },
{ name: 'Filerveien', completed: false },
{ name: 'Filips gate', completed: false },
{ name: 'Filipstad brygge', completed: false },
{ name: 'Filipstadveien', completed: false },
{ name: 'Finn Alexanders plass', completed: false },
{ name: 'Finnhaugveien', completed: false },
{ name: 'Finnmarkgata', completed: false },
{ name: 'Finns vei', completed: true },
{ name: 'Fiolveien', completed: false },
{ name: 'Firkløverveien', completed: false },
{ name: 'Fiskekroken', completed: false },
{ name: 'Fisker Syversens vei', completed: false },
{ name: 'Fjeldhøikroken', completed: false },
{ name: 'Fjellgata', completed: true },
{ name: 'Fjellhus allé', completed: false },
{ name: 'Fjellhøiveien', completed: false },
{ name: 'Fjellklangveien', completed: false },
{ name: 'Fjellknattveien', completed: false },
{ name: 'Fjellstien', completed: false },
{ name: 'Fjellstuveien', completed: false },
{ name: 'Fjordalléen', completed: false },
{ name: 'Fjordgløttveien', completed: true },
{ name: 'Fjordveien', completed: false },
{ name: 'Fjørtofts gate', completed: false },
{ name: 'Flaengrenda', completed: false },
{ name: 'Flaenveien', completed: false },
{ name: 'Flatengen', completed: false },
{ name: 'Flesåstunet', completed: false },
{ name: 'Flesåsveien', completed: false },
{ name: 'Florabakken', completed: false },
{ name: 'Flyveien', completed: false },
{ name: 'Folke Bernadottes vei', completed: false },
{ name: 'Folkvangveien', completed: false },
{ name: 'Formerveien', completed: false },
{ name: 'Forskningsveien', completed: false },
{ name: 'Fossefaret', completed: false },
{ name: 'Fossilveien', completed: false },
{ name: 'Fossumberget', completed: false },
{ name: 'Fossumkroken', completed: false },
{ name: 'Fossumveien', completed: false },
{ name: 'Fossveien', completed: false },
{ name: 'Fougners vei', completed: true },
{ name: 'Fougstads gate', completed: false },
{ name: 'Framnes terrasse', completed: false },
{ name: 'Framnesveien', completed: false },
{ name: 'Framveien', completed: false },
{ name: 'Frantzebråtveien', completed: false },
{ name: 'Fred Olsens gate', completed: false },
{ name: 'Fredensborgveien', completed: false },
{ name: 'Frederik Glads gate', completed: true },
{ name: 'Frederik Haslunds gate', completed: false },
{ name: 'Frederik Stangs gate', completed: false },
{ name: 'Frederiks gate', completed: false },
{ name: 'Fredheimveien', completed: false },
{ name: 'Fredrik Petersens gate', completed: false },
{ name: 'Fredrik Selmers vei', completed: true },
{ name: 'Fredrikke Qvams gate', completed: false },
{ name: 'Fredriksborgveien', completed: false },
{ name: 'Fredveien', completed: false },
{ name: 'Freidigveien', completed: false },
{ name: 'Frenningfaret', completed: false },
{ name: 'Frennings vei', completed: false },
{ name: 'Freserveien', completed: false },
{ name: 'Frichs gate', completed: false },
{ name: 'Fridtjof Nansens plass', completed: false },
{ name: 'Fridtjof Nansens vei', completed: false },
{ name: 'Fridtjovs gate', completed: true },
{ name: 'Frierveien', completed: false },
{ name: 'Friggs vei', completed: false },
{ name: 'Friis gate', completed: false },
{ name: 'Frimanns gate', completed: false },
{ name: 'Frits Kiærs vei', completed: false },
{ name: 'Fritzners gate', completed: false },
{ name: 'Frodes vei', completed: false },
{ name: 'Frogner plass', completed: false },
{ name: 'Frogner terrasse', completed: false },
{ name: 'Frognerseterveien', completed: false },
{ name: 'Frognerstranda', completed: false },
{ name: 'Frognerveien', completed: false },
{ name: 'Frostveien', completed: false },
{ name: 'Fru Kroghs brygge', completed: false },
{ name: 'Frydenbergbakken', completed: true },
{ name: 'Frydenbergveien', completed: true },
{ name: 'Frydenlundgata', completed: false },
{ name: 'Frydens gate', completed: false },
{ name: 'Frysjaveien', completed: false },
{ name: 'Frølichs vei', completed: false },
{ name: 'Frøensalléen', completed: false },
{ name: 'Frøyas gate', completed: false },
{ name: 'Frøydis vei', completed: false },
{ name: 'Fuglehauggata', completed: false },
{ name: 'Fugleliveien', completed: false },
{ name: 'Furer Nymos vei', completed: false },
{ name: 'Furubråtveien', completed: false },
{ name: 'Furukollveien', completed: false },
{ name: 'Furulundsveien', completed: false },
{ name: 'Furulundtoppen', completed: false },
{ name: 'Furumoen', completed: false },
{ name: 'Furuset allé', completed: false },
{ name: 'Furusetveien', completed: false },
{ name: 'Furustien', completed: false },
{ name: 'Furuveien', completed: true },
{ name: 'Fyrstikkalléen', completed: true },
{ name: 'Fyrstikkbakken', completed: false },
{ name: 'Fådveien', completed: false },
{ name: 'Gabbi Lunds vei', completed: false },
{ name: 'Gabels gate', completed: false },
{ name: 'Galgeberg', completed: false },
{ name: 'Gamle Brennavei', completed: false },
{ name: 'Gamle Bygdevei', completed: false },
{ name: 'Gamle Enebakkvei', completed: false },
{ name: 'Gamle Fossumvei', completed: false },
{ name: 'Gamle Heggelivei', completed: false },
{ name: 'Gamle Herregårdsvei', completed: false },
{ name: 'Gamle Hovsetervei', completed: false },
{ name: 'Gamle Kjelsåsvei', completed: false },
{ name: 'Gamle Leirdalsvei', completed: false },
{ name: 'Gamle Madserud allé', completed: false },
{ name: 'Gamle Maridalsvei', completed: false },
{ name: 'Gamle Strømsvei', completed: false },
{ name: 'Gamle Trondheimsveien', completed: false },
{ name: 'Gamlehagen', completed: false },
{ name: 'Gamlelinja', completed: false },
{ name: 'Gamleveien', completed: false },
{ name: 'Gange-Rolvs gate', completed: false },
{ name: 'Gangstuveien', completed: false },
{ name: 'Gardeveien', completed: false },
{ name: 'Gartnerveien', completed: true },
{ name: 'Garver Ytteborgs vei', completed: false },
{ name: 'Gaupefaret', completed: false },
{ name: 'Gaustadalléen', completed: false },
{ name: 'Gaustadveien', completed: false },
{ name: 'Geitmyrsveien', completed: false },
{ name: 'General Birchs gate', completed: false },
{ name: 'General Krohgs vei', completed: false },
{ name: 'General Ruges vei', completed: false },
{ name: 'Generallunden', completed: false },
{ name: 'Geologsvingen', completed: false },
{ name: 'Gerd Kjølaas plass', completed: false },
{ name: 'Geviret', completed: false },
{ name: 'Gildevangen', completed: true },
{ name: 'Gimle terrasse', completed: false },
{ name: 'Gimleveien', completed: false },
{ name: 'Gina Krogs vei', completed: false },
{ name: 'Giskehagen', completed: false },
{ name: 'Gisle Johnsons plass', completed: false },
{ name: 'Gisles vei', completed: false },
{ name: 'Gjennomfaret', completed: false },
{ name: 'Gjenveien', completed: false },
{ name: 'Gjerdesmutten', completed: false },
{ name: 'Gjerdrums vei', completed: true },
{ name: 'Gjøaveien', completed: false },
{ name: 'Gjøkbakken', completed: false },
{ name: 'Gjørstads gate', completed: false },
{ name: 'Gjøvikgata', completed: false },
{ name: 'Glacisgata', completed: false },
{ name: 'Gladengveien', completed: true },
{ name: 'Glads vei', completed: false },
{ name: 'Gladvoll terrasse', completed: false },
{ name: 'Gladvollveien', completed: false },
{ name: 'Glimmersvingen', completed: false },
{ name: 'Glimmerveien', completed: false },
{ name: 'Glitreveien', completed: false },
{ name: 'Glückstads gate', completed: false },
{ name: 'Godals vei', completed: false },
{ name: 'Godheimveien', completed: false },
{ name: 'Godlia parksti', completed: false },
{ name: 'Godliasvingen', completed: false },
{ name: 'Godliaveien', completed: false },
{ name: 'Golfstubben', completed: false },
{ name: 'Granbergstubben', completed: false },
{ name: 'Grandeveien', completed: false },
{ name: 'Granebakken', completed: false },
{ name: 'Granhekkveien', completed: false },
{ name: 'Granittveien', completed: false },
{ name: 'Grankollveien', completed: false },
{ name: 'Grankvisten', completed: false },
{ name: 'Granliveien', completed: false },
{ name: 'Gransdalen', completed: false },
{ name: 'Granstangen', completed: false },
{ name: 'Granstuveien', completed: false },
{ name: 'Gransveien', completed: false },
{ name: 'Grantunet', completed: false },
{ name: 'Granveien', completed: false },
{ name: 'Grasveien', completed: false },
{ name: 'Gravdalsveien', completed: false },
{ name: 'Grefsen allé', completed: false },
{ name: 'Grefsenkollveien', completed: false },
{ name: 'Grefsentunet', completed: false },
{ name: 'Grefsenveien', completed: false },
{ name: 'Gregers Grams vei', completed: false },
{ name: 'Grensen', completed: false },
{ name: 'Grensestien', completed: false },
{ name: 'Grensesvingen', completed: true },
{ name: 'Grenseveien', completed: true },
{ name: 'Grete Waitz plass', completed: false },
{ name: 'Grev Wedels plass', completed: false },
{ name: 'Greveveien', completed: false },
{ name: 'Grevlinglia', completed: false },
{ name: 'Grevlingveien', completed: false },
{ name: 'Griffenfeldts gate', completed: false },
{ name: 'Grimelundshaugen', completed: false },
{ name: 'Grimelundsveien', completed: false },
{ name: 'Grimstadgata', completed: false },
{ name: 'Grinda', completed: false },
{ name: 'Grindbakken', completed: false },
{ name: 'Griniveien', completed: false },
{ name: 'Grorudbakken', completed: false },
{ name: 'Grorudveien', completed: false },
{ name: 'Grottenveien', completed: false },
{ name: 'Grubbebakken', completed: false },
{ name: 'Grubbegata', completed: false },
{ name: 'Gruegata', completed: false },
{ name: 'Grundingen', completed: false },
{ name: 'Grüners gate', completed: false },
{ name: 'Grünerstubben', completed: false },
{ name: 'Grusveien', completed: false },
{ name: 'Gruvelia', completed: false },
{ name: 'Grytebekkveien', completed: false },
{ name: 'Gryteveien', completed: false },
{ name: 'Grønland', completed: false },
{ name: 'Grønlands torg', completed: false },
{ name: 'Grønlandsleiret', completed: false },
{ name: 'Grønlibakken', completed: false },
{ name: 'Grønliveien', completed: false },
{ name: 'Grønmoveien', completed: false },
{ name: 'Grønnegata', completed: false },
{ name: 'Grønsundveien', completed: false },
{ name: 'Grønvoll allé', completed: true },
{ name: 'Grøttumsveien', completed: false },
{ name: 'Gråbrødreveien', completed: false },
{ name: 'Grågåsveien', completed: false },
{ name: 'Graahbakken', completed: false },
{ name: 'Gråkamveien', completed: false },
{ name: 'Gråseterveien', completed: false },
{ name: 'Gråsteinveien', completed: false },
{ name: 'Guldberglia', completed: false },
{ name: 'Guldbergs vei', completed: false },
{ name: 'Gulleråsveien', completed: false },
{ name: 'Gullhaug torg', completed: true },
{ name: 'Gullhaugveien', completed: true },
{ name: 'Gullkroken', completed: false },
{ name: 'Gunhilds gate', completed: false },
{ name: 'Gunnar Johnsons vei', completed: false },
{ name: 'Gunnar Schjelderups vei', completed: true },
{ name: 'Gunnars vei', completed: false },
{ name: 'Gunnulvs vei', completed: false },
{ name: 'Gurinestubben', completed: false },
{ name: 'Guristuveien', completed: false },
{ name: 'Gustav Bloms gate', completed: false },
{ name: 'Gustav Heibergs vei', completed: false },
{ name: 'Gustav Jensens gate', completed: false },
{ name: 'Gustav Vigelands vei', completed: false },
{ name: 'Gustavs gate', completed: false },
{ name: 'Guvernørens vei', completed: false },
{ name: 'Gydas vei', completed: false },
{ name: 'Gyldenløves gate', completed: false },
{ name: 'Gäbleins vei', completed: false },
{ name: 'Gørbitz gate', completed: false },
{ name: 'Gøteborggata', completed: true },
{ name: 'Gårdsveien', completed: false },
{ name: 'Hafrsfjordgata', completed: false },
{ name: 'Hafslundveien', completed: false },
{ name: 'Hagaløkka', completed: false },
{ name: 'Hagan terrasse', completed: false },
{ name: 'Hagapynten', completed: false },
{ name: 'Hagastubben', completed: false },
{ name: 'Hagaveien', completed: false },
{ name: 'Hagegata', completed: false },
{ name: 'Hagelundveien', completed: false },
{ name: 'Hagtornveien', completed: false },
{ name: 'Hakadalsveien', completed: false },
{ name: 'Halfdan Kjerulfs plass', completed: false },
{ name: 'Hallagerbakken', completed: false },
{ name: 'Hallings gate', completed: false },
{ name: 'Hallvard Bergves vei', completed: false },
{ name: 'Halvdan Svartes gate', completed: false },
{ name: 'Halvor Blinderens plass', completed: false },
{ name: 'Halvor Schous gate', completed: false },
{ name: 'Halvor Torgersens vei', completed: false },
{ name: 'Hamborgveien', completed: false },
{ name: 'Hammerfestgata', completed: true },
{ name: 'Hammergata', completed: false },
{ name: 'Hammersborggata', completed: false },
{ name: 'Hammersborg torg', completed: false },
{ name: 'Hammerstads gate', completed: false },
{ name: 'Hanken', completed: false },
{ name: 'Hans Barliens gate', completed: false },
{ name: 'Hans C. Gjefles vei', completed: false },
{ name: 'Hans Møller Gasmanns vei', completed: false },
{ name: 'Hans Nielsen Hauges gate', completed: true },
{ name: 'Hans Nielsen Hauges plass', completed: true },
{ name: 'Hans Ross gate', completed: false },
{ name: 'Hans Steinpukkers vei', completed: false },
{ name: 'Hans Aanruds vei', completed: false },
{ name: 'Hansegata', completed: false },
{ name: 'Hansemyrveien', completed: false },
{ name: 'Hansteens gate', completed: false },
{ name: 'Hanåveien', completed: false },
{ name: 'Harald Halvorsens vei', completed: false },
{ name: 'Harald Hårdrådes plass', completed: false },
{ name: 'Harald Hårfagres gate', completed: false },
{ name: 'Harald Løvenskiolds vei', completed: false },
{ name: 'Harald Rømckes vei', completed: false },
{ name: 'Harald Sohlbergs vei', completed: false },
{ name: 'Haraldrudveien', completed: false },
{ name: 'Haralds vei', completed: true },
{ name: 'Haraldsheimveien', completed: false },
{ name: 'Haraløkka', completed: false },
{ name: 'Harbitzalléen', completed: false },
{ name: 'Harestien', completed: false },
{ name: 'Hareveien', completed: false },
{ name: 'Harry Fetts vei', completed: false },
{ name: 'Hartmanns vei', completed: false },
{ name: 'Hartvig Halvorsens vei', completed: false },
{ name: 'Haråsveien', completed: false },
{ name: 'Haslefetbakken', completed: false },
{ name: 'Haslekroken', completed: true },
{ name: 'Haslevangen', completed: true },
{ name: 'Hasleveien', completed: true },
{ name: 'Haslevollen', completed: true },
{ name: 'Hasselbakken', completed: false },
{ name: 'Hasselhaugveien', completed: false },
{ name: 'Hauchs gate', completed: false },
{ name: 'Haugakollveien', completed: false },
{ name: 'Haugenstien', completed: false },
{ name: 'Haugenstuveien', completed: false },
{ name: 'Haugerudbakken', completed: false },
{ name: 'Haugerudhagan', completed: false },
{ name: 'Haugerudsenteret', completed: false },
{ name: 'Haugerudtunet', completed: false },
{ name: 'Haugerudveien', completed: false },
{ name: 'Hauges vei', completed: false },
{ name: 'Haugmannsveien', completed: false },
{ name: 'Haukelibakken', completed: false },
{ name: 'Hauketosvingen', completed: false },
{ name: 'Hauketotoppen', completed: false },
{ name: 'Hauketoveien', completed: false },
{ name: 'Hauketoåsen', completed: false },
{ name: 'Hausmanns gate', completed: false },
{ name: 'Havna allé', completed: false },
{ name: 'Havnabakken', completed: false },
{ name: 'Havnehagan', completed: false },
{ name: 'Havreveien', completed: false },
{ name: 'Haxthausens gate', completed: false },
{ name: 'Hedmarksgata', completed: false },
{ name: 'Heftyebakken', completed: false },
{ name: 'Hegdehaugsveien', completed: false },
{ name: 'Hegermanns gate', completed: true },
{ name: 'Hegermanns plass', completed: true },
{ name: 'Heggelibakken', completed: false },
{ name: 'Heggeliveien', completed: false },
{ name: 'Heggestien', completed: false },
{ name: 'Heiasvingen', completed: false },
{ name: 'Heierstuveien', completed: false },
{ name: 'Heikampveien', completed: false },
{ name: 'Heimdalsgata', completed: false },
{ name: 'Heimlibakken', completed: false },
{ name: 'Hekkveien', completed: true },
{ name: 'Helga Helgesens plass', completed: false },
{ name: 'Helga Vaneks vei', completed: false },
{ name: 'Helgesens gate', completed: false },
{ name: 'Helge Sollies vei', completed: false },
{ name: 'Hellerud gårdsvei', completed: false },
{ name: 'Hellerud terrasse', completed: false },
{ name: 'Hellerudfaret', completed: false },
{ name: 'Hellerudgrenda', completed: false },
{ name: 'Hellerudstubben', completed: false },
{ name: 'Hellerudsvingen', completed: false },
{ name: 'Hellerudveien', completed: false },
{ name: 'Helleveien', completed: false },
{ name: 'Hellinga', completed: false },
{ name: 'Hemingveien', completed: false },
{ name: 'Hemmestveitbakken', completed: false },
{ name: 'Hengsengveien', completed: false },
{ name: 'Henny Mürers gang', completed: false },
{ name: 'Henrichsens gate', completed: false },
{ name: 'Henrik Backers vei', completed: false },
{ name: 'Henrik Ibsens gate', completed: false },
{ name: 'Henrik Klausens gate', completed: false },
{ name: 'Henrik Mohns plass', completed: false },
{ name: 'Henrik Sørensens vei', completed: false },
{ name: 'Henriks vei', completed: false },
{ name: 'Herbernveien', completed: false },
{ name: 'Herman Foss gate', completed: false },
{ name: 'Herregårdsveien', completed: false },
{ name: 'Herslebs gate', completed: false },
{ name: 'Hertug Skules gate', completed: false },
{ name: 'Hertzbergs gate', completed: false },
{ name: 'Hesjeveien', completed: false },
{ name: 'Hesselbergs gate', completed: false },
{ name: 'Hestehagen', completed: false },
{ name: 'Hesteskoen', completed: false },
{ name: 'Heyerdahls vei', completed: false },
{ name: 'Hieronymus Heyerdahls gate', completed: false },
{ name: 'Hjalmar Brantings vei', completed: false },
{ name: 'Hjalmar Jordans vei', completed: false },
{ name: 'Hjalmar Larsens gate', completed: false },
{ name: 'Hjaltlandsgata', completed: false },
{ name: 'Hjelms gate', completed: false },
{ name: 'Hjemliveien', completed: true },
{ name: 'Hjorteveien', completed: false },
{ name: 'Hjørungavåggata', completed: false },
{ name: 'Hjørungkroken', completed: false },
{ name: 'Hjørungveien', completed: false },
{ name: 'Hockeyveien', completed: false },
{ name: 'Hoff terrasse', completed: false },
{ name: 'Hoffsbakken', completed: false },
{ name: 'Hoffselvpromenaden', completed: false },
{ name: 'Hoffsjef Løvenskiolds vei', completed: false },
{ name: 'Hoffsveien', completed: false },
{ name: 'Hofftunet', completed: false },
{ name: 'Holbergs gate', completed: false },
{ name: 'Holbergs plass', completed: false },
{ name: 'Holgerslystveien', completed: false },
{ name: 'Hollendergata', completed: false },
{ name: 'Holmboes gate', completed: false },
{ name: 'Holmendammen terrasse', completed: false },
{ name: 'Holmenenga', completed: false },
{ name: 'Holmengrenda', completed: false },
{ name: 'Holmenhaugen', completed: false },
{ name: 'Holmenkollveien', completed: false },
{ name: 'Holmens gate', completed: false },
{ name: 'Holmensletta', completed: false },
{ name: 'Holmenveien', completed: false },
{ name: 'Holmestrandgata', completed: false },
{ name: 'Holmlia senter vei', completed: false },
{ name: 'Holmliaveien', completed: false },
{ name: 'Holmliveien', completed: false },
{ name: 'Holmsåsen', completed: false },
{ name: 'Holmveien', completed: false },
{ name: 'Holsteinveien', completed: false },
{ name: 'Holsts gate', completed: false },
{ name: 'Holsts vei', completed: false },
{ name: 'Holtegata', completed: false },
{ name: 'Holtveien', completed: false },
{ name: 'Homansbakken', completed: false },
{ name: 'Hoppveien', completed: false },
{ name: 'Hornfjellveien', completed: false },
{ name: 'Hortengata', completed: false },
{ name: 'Hospitalsgata', completed: false },
{ name: 'Hospitsveien', completed: false },
{ name: 'Hovfaret', completed: false },
{ name: 'Hovinveien', completed: true },
{ name: 'Hovseterveien', completed: false },
{ name: 'Hubroveien', completed: false },
{ name: 'Huitfeldts gate', completed: false },
{ name: 'Huk aveny', completed: false },
{ name: 'Huk terrasse', completed: false },
{ name: 'Hukenveien', completed: false },
{ name: 'Hukgrenda', completed: false },
{ name: 'Huldreveien', completed: false },
{ name: 'Humleveien', completed: false },
{ name: 'Hummerkloa', completed: false },
{ name: 'Hurdalsgata', completed: false },
{ name: 'Husebybakken', completed: false },
{ name: 'Husebygrenda', completed: false },
{ name: 'Husebysletta', completed: false },
{ name: 'Husebyveien', completed: false },
{ name: 'Hvitveisbakken', completed: false },
{ name: 'Hyggeveien', completed: false },
{ name: 'Hyllveien', completed: false },
{ name: 'Høgdaveien', completed: false },
{ name: 'Høgdefaret', completed: false },
{ name: 'Høgtunveien', completed: false },
{ name: 'Høgåsveien', completed: false },
{ name: 'Hølandsgata', completed: false },
{ name: 'Høvleriveien', completed: false },
{ name: 'Høyboveien', completed: false },
{ name: 'Høybråtenstien', completed: false },
{ name: 'Høybråtenveien', completed: false },
{ name: 'Høydalsveien', completed: false },
{ name: 'Høyenhall plass', completed: true },
{ name: 'Høyenhallsvingen', completed: true },
{ name: 'Høyenhallveien', completed: true },
{ name: 'Høyesteretts plass', completed: false },
{ name: 'Høystakkveien', completed: false },
{ name: 'Haakon Aunes vei', completed: false },
{ name: 'Haakon den godes vei', completed: false },
{ name: 'Haakon Tveters vei', completed: false },
{ name: 'Haakon VIIs gate', completed: false },
{ name: 'Håkons gate', completed: false },
{ name: 'Haarklous plass', completed: true },
{ name: 'Håvalds vei', completed: false },
{ name: 'Haavard Martinsens vei', completed: false },
{ name: 'Idas vei', completed: false },
{ name: 'Idrettsveien', completed: false },
{ name: 'Iduns gate', completed: false },
{ name: 'Ilagata', completed: false },
{ name: 'Industrigata', completed: false },
{ name: 'Inga Bjørnsons vei', completed: false },
{ name: 'Ingar Nilsens vei', completed: false },
{ name: 'Ingeborgs gate', completed: false },
{ name: 'Ingebret Andersens vei', completed: false },
{ name: 'Ingegjerds vei', completed: false },
{ name: 'Ingelbrecht Knudssøns gate', completed: false },
{ name: 'Ingeniørveien', completed: false },
{ name: 'Ingens gate', completed: false },
{ name: 'Inger Hagerups plass', completed: false },
{ name: 'Inges gate', completed: false },
{ name: 'Ingiers vei', completed: false },
{ name: 'Ingolf Ruuds vei', completed: false },
{ name: 'Ingrid Bjerkås plass', completed: false },
{ name: 'Inkognitogata', completed: false },
{ name: 'Inkognito terrasse', completed: false },
{ name: 'Innspurten', completed: true },
{ name: 'Irisveien', completed: false },
{ name: 'Islands gate', completed: false },
{ name: 'Ivan Bjørndals gate', completed: true },
{ name: 'Ivar Knutsons vei', completed: false },
{ name: 'Ivar Welles vei', completed: false },
{ name: 'Ivar Aasens vei', completed: false },
{ name: 'Iver Olsens vei', completed: false },
{ name: 'Jacob Fayes vei', completed: false },
{ name: 'Jacob Hansens vei', completed: false },
{ name: 'Jacob Aalls gate', completed: false },
{ name: 'Jacobine Ryes vei', completed: false },
{ name: 'Jansbergveien', completed: false },
{ name: 'Jarbakken', completed: false },
{ name: 'Jarlegata', completed: false },
{ name: 'Jarlsborgveien', completed: false },
{ name: 'Jegerveien', completed: false },
{ name: 'Jegersborgveien', completed: false },
{ name: 'Jenny Braatens plass', completed: false },
{ name: 'Jens Bjelkes gate', completed: false },
{ name: 'Jens Evensens plass', completed: false },
{ name: 'Jensmessveien', completed: false },
{ name: 'Jeppes vei', completed: false },
{ name: 'Jerikoveien', completed: false },
{ name: 'Jernbanetorget', completed: false },
{ name: 'Jernbaneveien', completed: false },
{ name: 'Jernkroken', completed: false },
{ name: 'Jerpefaret', completed: false },
{ name: 'Jess Carlsens gate', completed: false },
{ name: 'Jetteveien', completed: false },
{ name: 'Joachim Nielsens gang', completed: false },
{ name: 'Johan Castbergs vei', completed: false },
{ name: 'Johan Evjes vei', completed: false },
{ name: 'Johan Hirsch vei', completed: true },
{ name: 'Johan Nygaardsvolds plass', completed: false },
{ name: 'Johan Scharffenbergs vei', completed: false },
{ name: 'Johan Selmers gate', completed: true },
{ name: 'Johan Svendsens gate', completed: true },
{ name: 'Johan Svendsens plass', completed: false },
{ name: 'Johan Sverdrups vei', completed: false },
{ name: 'Johan Throne Holsts plass', completed: true },
{ name: 'Johanne Dybwads plass', completed: false },
{ name: 'Johannes Bruns gate', completed: false },
{ name: 'John Brandts vei', completed: false },
{ name: 'John Colletts allé', completed: false },
{ name: 'John Colletts plass', completed: false },
{ name: 'John G. Mattesons vei', completed: false },
{ name: 'Johnny Svorkmos vei', completed: false },
{ name: 'Jolly Kramer-Johansens gate', completed: true },
{ name: 'Jomfrubråtveien', completed: false },
{ name: 'Jomfruhagen', completed: false },
{ name: 'Jomfrustien', completed: false },
{ name: 'Jon P. Erliens vei', completed: false },
{ name: 'Jon Smestads vei', completed: false },
{ name: 'Jonas Dahls vei', completed: false },
{ name: 'Jonas Reins gate', completed: false },
{ name: 'Jonsokveien', completed: false },
{ name: 'Jonsrudveien', completed: false },
{ name: 'Jordal terrasse', completed: false },
{ name: 'Jordalgata', completed: false },
{ name: 'Jordbærveien', completed: false },
{ name: 'Jordstjerneveien', completed: false },
{ name: 'Jorines vei', completed: false },
{ name: 'Josefines gate', completed: false },
{ name: 'Jotunveien', completed: false },
{ name: 'Jupiterveien', completed: false },
{ name: 'Jutulveien', completed: false },
{ name: 'Jøranstien', completed: false },
{ name: 'Jørgen Løvlands gate', completed: true },
{ name: 'Jørgen Moes gate', completed: false },
{ name: 'Jørnsløkkveien', completed: false },
{ name: 'Kabelgata', completed: false },
{ name: 'Kaiekroken', completed: false },
{ name: 'Kaj Munks vei', completed: false },
{ name: 'Kakkelovnskroken', completed: false },
{ name: 'Kalbakkfaret', completed: false },
{ name: 'Kalbakkslyngen', completed: false },
{ name: 'Kalbakkstubben', completed: false },
{ name: 'Kalbakkveien', completed: false },
{ name: 'Kallandveien', completed: false },
{ name: 'Kallerudveien', completed: false },
{ name: 'Kampen Hageby', completed: false },
{ name: 'Kampengata', completed: false },
{ name: 'Kampheimveien', completed: false },
{ name: 'Kanalen', completed: false },
{ name: 'Kanonhallveien', completed: true },
{ name: 'Kanslergata', completed: false },
{ name: 'Kantarellen terrasse', completed: false },
{ name: 'Kapellveien', completed: false },
{ name: 'Kaptein Oppegaards vei', completed: false },
{ name: 'Karen Platous vei', completed: false },
{ name: 'Karenslyst allé', completed: false },
{ name: 'Karihaugveien', completed: false },
{ name: 'Karistien', completed: false },
{ name: 'Karistuveien', completed: false },
{ name: 'Karl Andersens vei', completed: false },
{ name: 'Karl Flods vei', completed: false },
{ name: 'Karl Fossums vei', completed: false },
{ name: 'Karl Johans gate', completed: false },
{ name: 'Karl Staaffs vei', completed: false },
{ name: 'Karlsborgveien', completed: false },
{ name: 'Karlsrudveien', completed: false },
{ name: 'Karlstadgata', completed: true },
{ name: 'Karoline Kristiansens vei', completed: true },
{ name: 'Karolinerveien', completed: false },
{ name: 'Karsten Kjelbergs vei', completed: false },
{ name: 'Karvesvingen', completed: true },
{ name: 'Kasaveien', completed: false },
{ name: 'Kastanjeveien', completed: false },
{ name: 'Kastellbakken', completed: false },
{ name: 'Kastellhagen', completed: false },
{ name: 'Kastellveien', completed: false },
{ name: 'Kathe Lasniks plass', completed: false },
{ name: 'Katy Dues vei', completed: false },
{ name: 'Kavringen brygge', completed: false },
{ name: 'Keysers gate', completed: false },
{ name: 'Kierschows gate', completed: false },
{ name: 'Kikkutveien', completed: false },
{ name: 'Kildals vei', completed: true },
{ name: 'Kildeveien', completed: false },
{ name: 'Kingos gate', completed: false },
{ name: 'Kirkebakken', completed: false },
{ name: 'Kirkegata', completed: false },
{ name: 'Kirkegårdsbakken', completed: false },
{ name: 'Kirkegårdsgata', completed: false },
{ name: 'Kirkehaugsveien', completed: false },
{ name: 'Kirkesvingen', completed: false },
{ name: 'Kirkeveien', completed: false },
{ name: 'Kirkeåsveien', completed: false },
{ name: 'Kirsten Flagstads plass', completed: false },
{ name: 'Kirsten Hansteens plass', completed: false },
{ name: 'Kittel-Nielsens vei', completed: false },
{ name: 'Kjeld Stubs gate', completed: false },
{ name: 'Kjelsåsløkka', completed: false },
{ name: 'Kjelsåsveien', completed: false },
{ name: 'Kjetils vei', completed: false },
{ name: 'Kjærlighetsstien', completed: false },
{ name: 'Kjølberggata', completed: false },
{ name: 'Klaus Torgårds vei', completed: false },
{ name: 'Kleiva', completed: false },
{ name: 'Klemetsrudveien', completed: false },
{ name: 'Klingenberggata', completed: false },
{ name: 'Klinkerveien', completed: false },
{ name: 'Klokkergata', completed: false },
{ name: 'Klostergata', completed: false },
{ name: 'Klosterheimveien', completed: false },
{ name: 'Kløfterhagen', completed: false },
{ name: 'Kløverveien', completed: false },
{ name: 'Kneika', completed: false },
{ name: 'Knud Bryns vei', completed: false },
{ name: 'Knud Graahs gate', completed: true },
{ name: 'Knud Knudsens plass', completed: false },
{ name: 'Knud Øyens vei', completed: false },
{ name: 'Knut Alvssons vei', completed: true },
{ name: 'Knut Nystedts plass', completed: false },
{ name: 'Knut Valstads vei', completed: false },
{ name: 'Kobbernaglen', completed: false },
{ name: 'Kolderups vei', completed: false },
{ name: 'Kollstien', completed: false },
{ name: 'Kolonihagestien', completed: false },
{ name: 'Kolstadgata', completed: false },
{ name: 'Kommandør T. I. Øgrims plass', completed: false },
{ name: 'Kongens gate', completed: false },
{ name: 'Kongeveien', completed: false },
{ name: 'Konghellegata', completed: true },
{ name: 'Kong Håkon 5.s gate', completed: false },
{ name: 'Konglerudtoppen', completed: false },
{ name: 'Kongleveien', completed: false },
{ name: 'Kongsberggata', completed: false },
{ name: 'Kongshavnveien', completed: false },
{ name: 'Kongsstien', completed: false },
{ name: 'Kongsveien', completed: false },
{ name: 'Kongsvingergata', completed: false },
{ name: 'Konows gate', completed: false },
{ name: 'Konsul Schjelderups vei', completed: false },
{ name: 'Konvallveien', completed: false },
{ name: 'Konventveien', completed: false },
{ name: 'Kopperuds vei', completed: false },
{ name: 'Korallveien', completed: false },
{ name: 'Korsgata', completed: false },
{ name: 'Korsvoll terrasse', completed: false },
{ name: 'Korsvollbakken', completed: false },
{ name: 'Korsvollbråtan', completed: false },
{ name: 'Kortbølgen', completed: false },
{ name: 'Krafts gate', completed: false },
{ name: 'Krags terrasse', completed: false },
{ name: 'Krags vei', completed: false },
{ name: 'Kragstubben', completed: false },
{ name: 'Kransen', completed: false },
{ name: 'Kranveien', completed: false },
{ name: 'Krebs gate', completed: true },
{ name: 'Kringkollen', completed: false },
{ name: 'Kringlebekkveien', completed: false },
{ name: 'Kringsjågrenda', completed: false },
{ name: 'Kringveien', completed: false },
{ name: 'Kristian Auberts vei', completed: false },
{ name: 'Kristian Augusts gate', completed: false },
{ name: 'Kristian Bogneruds vei', completed: false },
{ name: 'Kristian IVs gate', completed: false },
{ name: 'Kristianiasvingen', completed: false },
{ name: 'Kristiansands gate', completed: false },
{ name: 'Kristine Bonnevies vei', completed: false },
{ name: 'Kristinelundveien', completed: false },
{ name: 'Kristins vei', completed: false },
{ name: 'Kristoffer Robins vei', completed: false },
{ name: 'Kristoffer Aamots gate', completed: true },
{ name: 'Krogs gate', completed: false },
{ name: 'Kroken', completed: false },
{ name: 'Krokkleiva', completed: false },
{ name: 'Krokliveien', completed: false },
{ name: 'Krokstien', completed: false },
{ name: 'Krokusveien', completed: false },
{ name: 'Kronprinsens gate', completed: false },
{ name: 'Kronprinsesse Märthas plass', completed: false },
{ name: 'Kronveien', completed: false },
{ name: 'Krossveien', completed: false },
{ name: 'Krumgata', completed: false },
{ name: 'Kruses gate', completed: false },
{ name: 'Kruttveien', completed: true },
{ name: 'Kryssveien', completed: false },
{ name: 'Kudalsveien', completed: false },
{ name: 'Kurlandstien', completed: false },
{ name: 'Kurveien', completed: false },
{ name: 'Kvartsveien', completed: false },
{ name: 'Kvernfaret', completed: false },
{ name: 'Kvernkallveien', completed: false },
{ name: 'Kvernstien', completed: false },
{ name: 'Kvernveien', completed: false },
{ name: 'Kvistveien', completed: true },
{ name: 'Kværnerveien', completed: false },
{ name: 'Kyhns vei', completed: false },
{ name: 'Kyrre Grepps gate', completed: true },
{ name: 'Københavngata', completed: true },
{ name: 'Kaare Røddes plass', completed: false },
{ name: 'Kåres vei', completed: false },
{ name: 'Lachmanns vei', completed: false },
{ name: 'Ladeveien', completed: false },
{ name: 'Lagerkroken', completed: false },
{ name: 'Lakkegata', completed: false },
{ name: 'Lallakroken', completed: false },
{ name: 'Lambertseterveien', completed: false },
{ name: 'Lambrechts gate', completed: false },
{ name: 'Lammers gate', completed: true },
{ name: 'Landerokollen', completed: false },
{ name: 'Landeroveien', completed: false },
{ name: 'Landgangen', completed: false },
{ name: 'Landingsveien', completed: false },
{ name: 'Landstads gate', completed: false },
{ name: 'Langbølgen', completed: false },
{ name: 'Langengen', completed: true },
{ name: 'Langerudhaugen', completed: false },
{ name: 'Langerudsvingen', completed: false },
{ name: 'Langerudveien', completed: false },
{ name: 'Langes gate', completed: false },
{ name: 'Langgata', completed: true },
{ name: 'Langkaia', completed: false },
{ name: 'Langlia', completed: false },
{ name: 'Langlibakken', completed: false },
{ name: 'Langliveien', completed: false },
{ name: 'Langmyrgrenda', completed: false },
{ name: 'Langmyrveien', completed: false },
{ name: 'Langretta', completed: false },
{ name: 'Langs linjen', completed: false },
{ name: 'Langsetveien', completed: false },
{ name: 'Langteigåsen', completed: false },
{ name: 'Langviksveien', completed: false },
{ name: 'Langaards gate', completed: false },
{ name: 'Langåsveien', completed: false },
{ name: 'Larsbråtveien', completed: false },
{ name: 'Larviksgata', completed: false },
{ name: 'Lassons gate', completed: false },
{ name: 'Laura Gundersens gate', completed: false },
{ name: 'Lauvlundveien', completed: false },
{ name: 'Lavrans vei', completed: false },
{ name: 'Legatveien', completed: false },
{ name: 'Leirfallsgata', completed: false },
{ name: 'Leirskallbakken', completed: false },
{ name: 'Leirskallen', completed: false },
{ name: 'Leirskallhellinga', completed: false },
{ name: 'Leirskallsvingen', completed: false },
{ name: 'Leiv Eirikssons gate', completed: false },
{ name: 'Lekeplassveien', completed: false },
{ name: 'Lena Hiorths plass', completed: false },
{ name: 'Lensmann Hiorths allé', completed: true },
{ name: 'Lerdalsfaret', completed: false },
{ name: 'Lerdalsgrenda', completed: false },
{ name: 'Lerdalstoppen', completed: false },
{ name: 'Lerdalsveien', completed: false },
{ name: 'Lerkeveien', completed: false },
{ name: 'Lersolveien', completed: false },
{ name: 'Lettvintveien', completed: false },
{ name: 'Leyrins gate', completed: true },
{ name: 'Liakollveien', completed: false },
{ name: 'Liaveien', completed: false },
{ name: 'Libakkfaret', completed: false },
{ name: 'Libakkveien', completed: false },
{ name: 'Liljeveien', completed: true },
{ name: 'Lille Bislett', completed: false },
{ name: 'Lille Borgen vei', completed: false },
{ name: 'Lille Frogner allé', completed: false },
{ name: 'Lille Frøens vei', completed: false },
{ name: 'Lille Grensen', completed: false },
{ name: 'Lille Huseby vei', completed: false },
{ name: 'Lille Stensrud', completed: false },
{ name: 'Lille Stranden', completed: false },
{ name: 'Lilleakerveien', completed: false },
{ name: 'Lillebakken', completed: false },
{ name: 'Lillebergsvingen', completed: false },
{ name: 'Lillebergveien', completed: false },
{ name: 'Lilleborggata', completed: true },
{ name: 'Lilletorget', completed: false },
{ name: 'Lillevannsveien', completed: false },
{ name: 'Lilleveien', completed: false },
{ name: 'Lilloe-Olsens vei', completed: false },
{ name: 'Lillogata', completed: true },
{ name: 'Lilloseterveien', completed: false },
{ name: 'Lindbäckveien', completed: false },
{ name: 'Lindealléen', completed: false },
{ name: 'Lindeberglia', completed: false },
{ name: 'Lindebergveien', completed: false },
{ name: 'Lindebergåsen', completed: false },
{ name: 'Lindemans gate', completed: false },
{ name: 'Linderngata', completed: false },
{ name: 'Linderudsletta', completed: false },
{ name: 'Linderudveien', completed: false },
{ name: 'Lindøya', completed: false },
{ name: 'Linhusveien', completed: false },
{ name: 'Linjebakken', completed: false },
{ name: 'Linjeveien', completed: false },
{ name: 'Linneaveien', completed: false },
{ name: 'Linstows gate', completed: false },
{ name: 'Linaaes gate', completed: false },
{ name: 'Lisa Kristoffersens plass', completed: false },
{ name: 'Ljabrubakken', completed: false },
{ name: 'Ljabrudiagonalen', completed: false },
{ name: 'Ljabrukollen terrasse', completed: false },
{ name: 'Ljabruveien', completed: false },
{ name: 'Ljan terrasse', completed: false },
{ name: 'Ljansbakken', completed: false },
{ name: 'Ljansbrukveien', completed: false },
{ name: 'Lofotgata', completed: false },
{ name: 'Lofsrudhøgda', completed: false },
{ name: 'Lofsrudveien', completed: false },
{ name: 'Lofthus terrasse', completed: false },
{ name: 'Lofthusveien', completed: false },
{ name: 'Lohavngata', completed: false },
{ name: 'Lokkeberget', completed: false },
{ name: 'Louises gate', completed: false },
{ name: 'Lovisenberggata', completed: false },
{ name: 'Lovisenlund', completed: false },
{ name: 'Ludvig Karstens vei', completed: false },
{ name: 'Luftfartsveien', completed: false },
{ name: 'Lunaveien', completed: false },
{ name: 'Lunden', completed: false },
{ name: 'Lundliveien', completed: false },
{ name: 'Lundveien', completed: true },
{ name: 'Lundåsveien', completed: false },
{ name: 'Lunhvileveien', completed: false },
{ name: 'Lunnestien', completed: false },
{ name: 'Lusetjernveien', completed: false },
{ name: 'Lutvannskroken', completed: false },
{ name: 'Lutvannsveien', completed: false },
{ name: 'Lybekkergata', completed: false },
{ name: 'Lybekkveien', completed: false },
{ name: 'Lyberget', completed: false },
{ name: 'Lyder Sagens gate', completed: false },
{ name: 'Lyngtrekket', completed: false },
{ name: 'Lyngveien', completed: false },
{ name: 'Lyngåsveien', completed: false },
{ name: 'Lysebuveien', completed: false },
{ name: 'Lysehagan', completed: false },
{ name: 'Lyseskrenten', completed: false },
{ name: 'Lyseveien', completed: false },
{ name: 'Lytterveien', completed: false },
{ name: 'Lyveien', completed: false },
{ name: 'Lærer Holes vei', completed: false },
{ name: 'Løchenveien', completed: false },
{ name: 'Løkkalia', completed: false },
{ name: 'Løkkaskogen', completed: false },
{ name: 'Løkkegangen', completed: false },
{ name: 'Løkkeveien', completed: false },
{ name: 'Lønneveien', completed: false },
{ name: 'Lønnhaugen allé', completed: false },
{ name: 'Lønnåsveien', completed: false },
{ name: 'Lørenfaret', completed: true },
{ name: 'Lørenvangen', completed: true },
{ name: 'Lørenveien', completed: true },
{ name: 'Løvenskiolds gate', completed: false },
{ name: 'Løvsetdalen', completed: false },
{ name: 'Løvsetfaret', completed: false },
{ name: 'Løvåsveien', completed: false },
{ name: 'Løypeveien', completed: false },
{ name: 'Låveveien', completed: false },
{ name: 'Madame Maren Juels vei', completed: false },
{ name: 'Madserud allé', completed: false },
{ name: 'Magnefaret', completed: true },
{ name: 'Magnus Barfots gate', completed: false },
{ name: 'Magnus Bergs gate', completed: false },
{ name: 'Magnus Hydles vei', completed: false },
{ name: 'Magnus gate', completed: false },
{ name: 'Mailundveien', completed: true },
{ name: 'Majorstuveien', completed: false },
{ name: 'Malerhaugveien', completed: true },
{ name: 'Malmøgata', completed: true },
{ name: 'Malmøyveien', completed: false },
{ name: 'Mandalls gate', completed: false },
{ name: 'Manglebergveien', completed: false },
{ name: 'Manglerudveien', completed: true },
{ name: 'Marcus Thranes gate', completed: false },
{ name: 'Margit Hansens gate', completed: false },
{ name: 'Margrethe Munthes plass', completed: false },
{ name: 'Margrethe Parms vei', completed: false },
{ name: 'Maria Dehlis vei', completed: false },
{ name: 'Mariboes gate', completed: false },
{ name: 'Maridalsveien', completed: false },
{ name: 'Marienlundveien', completed: false },
{ name: 'Maries gate', completed: false },
{ name: 'Mariholtveien', completed: false },
{ name: 'Marits vei', completed: false },
{ name: 'Markstien', completed: false },
{ name: 'Markveien', completed: false },
{ name: 'Marmorveien', completed: false },
{ name: 'Marselis gate', completed: false },
{ name: 'Marstrandgata', completed: false },
{ name: 'Marsveien', completed: false },
{ name: 'Marta Steinsviks vei', completed: false },
{ name: 'Martha Tynes vei', completed: false },
{ name: 'Martin Borrebekkens vei', completed: false },
{ name: 'Martin Linges vei', completed: false },
{ name: 'Martin Skatvedts vei', completed: false },
{ name: 'Martin Strandlis vei', completed: false },
{ name: 'Martinus Lørdahls plass', completed: false },
{ name: 'Mastrups gate', completed: true },
{ name: 'Mauritz Hansens gate', completed: false },
{ name: 'Maurstien', completed: false },
{ name: 'Maurtuveien', completed: false },
{ name: 'Mekanikerveien', completed: false },
{ name: 'Meklenborglia', completed: false },
{ name: 'Meklenborgveien', completed: false },
{ name: 'Meklenborgåsen', completed: false },
{ name: 'Melkeveien', completed: false },
{ name: 'Mellbyedalen', completed: false },
{ name: 'Mellombølgen', completed: false },
{ name: 'Meltzers gate', completed: false },
{ name: 'Melumveien', completed: false },
{ name: 'Merkurveien', completed: false },
{ name: 'Messepromenaden', completed: false },
{ name: 'Michael von Sundts plass', completed: false },
{ name: 'Michel Nielsens vei', completed: false },
{ name: 'Micheletveien', completed: false },
{ name: 'Middelthuns gate', completed: false },
{ name: 'Midtoddveien', completed: false },
{ name: 'Midtstugrenda', completed: false },
{ name: 'Midttunveien', completed: false },
{ name: 'Midtveien', completed: false },
{ name: 'Midtåsen', completed: false },
{ name: 'Mikael Hertzbergs vei', completed: false },
{ name: 'Mikjelsbakken', completed: false },
{ name: 'Mikkel Doblougs gate', completed: false },
{ name: 'Mikkel Revs vei', completed: false },
{ name: 'Mikrobølgen', completed: false },
{ name: 'Mina Beiteplukks vei', completed: false },
{ name: 'Minister Ditleffs vei', completed: false },
{ name: 'Minåsveien', completed: false },
{ name: 'Mogata', completed: false },
{ name: 'Mogens Thorsens gate', completed: false },
{ name: 'Moldegata', completed: false },
{ name: 'Moltke Moes vei', completed: false },
{ name: 'Monolitveien', completed: false },
{ name: 'Monrads gate', completed: false },
{ name: 'Mons Søviks plass', completed: false },
{ name: 'Montebello terrasse', completed: false },
{ name: 'Montebellobakken', completed: false },
{ name: 'Montebelloveien', completed: false },
{ name: 'Mor Gohjertas vei', completed: false },
{ name: 'Morells vei', completed: false },
{ name: 'Morenestien', completed: false },
{ name: 'Morgedalsvegen', completed: false },
{ name: 'Mortensrudveien', completed: false },
{ name: 'Mortensvingen', completed: false },
{ name: 'Mosekollen', completed: false },
{ name: 'Moserabben', completed: false },
{ name: 'Mosse Jørgensens plass', completed: false },
{ name: 'Mosseveien', completed: false },
{ name: 'Motbakkene', completed: false },
{ name: 'Motzfeldts gate', completed: false },
{ name: 'Movassbakken', completed: false },
{ name: 'Munchs gate', completed: false },
{ name: 'Munkebekken', completed: false },
{ name: 'Munkedamsveien', completed: false },
{ name: 'Munkegata', completed: false },
{ name: 'Munkelia', completed: false },
{ name: 'Munkengveien', completed: false },
{ name: 'Munkerudbakken', completed: false },
{ name: 'Munkerudkleiva', completed: false },
{ name: 'Munkerudstubben', completed: false },
{ name: 'Munkerudtunet', completed: false },
{ name: 'Munkerudveien', completed: false },
{ name: 'Munkerudvollen', completed: false },
{ name: 'Munkerudåsen', completed: false },
{ name: 'Munthes gate', completed: false },
{ name: 'Muselundgrenda', completed: false },
{ name: 'Museumsveien', completed: false },
{ name: 'Mustads vei', completed: false },
{ name: 'Myklegardgata', completed: false },
{ name: 'Mylskerudveien', completed: false },
{ name: 'Myntfunnveien', completed: false },
{ name: 'Myntgata', completed: false },
{ name: 'Myrbakkveien', completed: false },
{ name: 'Myrdalveien', completed: false },
{ name: 'Myrerdraget', completed: false },
{ name: 'Myrerskogveien', completed: false },
{ name: 'Myrerveien', completed: false },
{ name: 'Myrhaugen', completed: false },
{ name: 'Mælosvingen', completed: false },
{ name: 'Møllefaret', completed: false },
{ name: 'Mølleparken', completed: false },
{ name: 'Møllergata', completed: false },
{ name: 'Møllerveien', completed: false },
{ name: 'Møllesvingen', completed: false },
{ name: 'Måkeveien', completed: false },
{ name: 'Måltrostveien', completed: false },
{ name: 'Mårveien', completed: false },
{ name: 'Nandrups vei', completed: false },
{ name: 'Nannestadgata', completed: false },
{ name: 'Nebbejordet', completed: false },
{ name: 'Nedre gate', completed: false },
{ name: 'Nedre Kalbakkvei', completed: false },
{ name: 'Nedre Prinsdals vei', completed: false },
{ name: 'Nedre Rommen', completed: false },
{ name: 'Nedre Silkestrå', completed: false },
{ name: 'Nedre Skogvei', completed: false },
{ name: 'Nedre Skøyen vei', completed: false },
{ name: 'Nedre Slottsgate', completed: false },
{ name: 'Nedre Stabburvei', completed: false },
{ name: 'Nedre Ullern terrasse', completed: false },
{ name: 'Nedre Ullevål', completed: false },
{ name: 'Nedre Vaskegang', completed: false },
{ name: 'Nedre Vollgate', completed: false },
{ name: 'Nedslagsveien', completed: false },
{ name: 'Nellikveien', completed: false },
{ name: 'Neptunveien', completed: false },
{ name: 'Neuberggata', completed: false },
{ name: 'Nico Hambros vei', completed: false },
{ name: 'Nic Waals vei', completed: false },
{ name: 'Niels Henrik Abels vei', completed: false },
{ name: 'Niels Juels gate', completed: false },
{ name: 'Nielsenbakken', completed: false },
{ name: 'Nils Bays vei', completed: false },
{ name: 'Nils Collett Vogts vei', completed: false },
{ name: 'Nils Hansens vei', completed: false },
{ name: 'Nils Huus gate', completed: true },
{ name: 'Nils Lauritssøns vei', completed: false },
{ name: 'Nils Tollers vei', completed: false },
{ name: 'Nilserudkleiva', completed: false },
{ name: 'Nini Roll Ankers plass', completed: false },
{ name: 'Nissens gate', completed: false },
{ name: 'Nittedalgata', completed: false },
{ name: 'Njåls vei', completed: true },
{ name: 'Nobels gate', completed: false },
{ name: 'Nonnegata', completed: false },
{ name: 'Norbygata', completed: false },
{ name: 'Nordahl Bruns gate', completed: false },
{ name: 'Nordahl Rolfsens plass', completed: false },
{ name: 'Nordalveien', completed: false },
{ name: 'Nordbergbakken', completed: false },
{ name: 'Nordbergveien', completed: false },
{ name: 'Nordengstubben', completed: false },
{ name: 'Nordengveien', completed: false },
{ name: 'Norderhovgata', completed: false },
{ name: 'Nordhagaveien', completed: false },
{ name: 'Nordheimbakken', completed: false },
{ name: 'Nordkappgata', completed: true },
{ name: 'Nordlandsgata', completed: false },
{ name: 'Nordlisletta', completed: false },
{ name: 'Nordlisvingen', completed: false },
{ name: 'Nordlitoppen', completed: false },
{ name: 'Nordliveien', completed: true },
{ name: 'Nordlysveien', completed: false },
{ name: 'Nordmarka', completed: false },
{ name: 'Nordmarkveien', completed: false },
{ name: 'Nordmørgata', completed: false },
{ name: 'Nordpolen', completed: false },
{ name: 'Nordre Dals vei', completed: false },
{ name: 'Nordre gate', completed: false },
{ name: 'Nordraaks gate', completed: false },
{ name: 'Nordraaks plass', completed: false },
{ name: 'Nordseter terrasse', completed: false },
{ name: 'Nordsetergrenda', completed: false },
{ name: 'Nordseterstien', completed: false },
{ name: 'Nordseterveien', completed: false },
{ name: 'Nordstjerneveien', completed: false },
{ name: 'Nordstrand terrasse', completed: false },
{ name: 'Nordstrandveien', completed: false },
{ name: 'Nordtvetbakken', completed: false },
{ name: 'Nordtvetveien', completed: false },
{ name: 'Nordåssløyfa', completed: false },
{ name: 'Nordåsveien', completed: false },
{ name: 'Noreveien', completed: false },
{ name: 'Normandieplassen', completed: false },
{ name: 'Normannsgata', completed: false },
{ name: 'Normaveien', completed: true },
{ name: 'Nuggerudveien', completed: false },
{ name: 'Numedalsgata', completed: false },
{ name: 'Nybrottveien', completed: false },
{ name: 'Nybyggerveien', completed: false },
{ name: 'Nycoveien', completed: true },
{ name: 'Nydalen allé', completed: true },
{ name: 'Nydalen Bruks vei', completed: false },
{ name: 'Nydalsveien', completed: true },
{ name: 'Nye Heggelivei', completed: false },
{ name: 'Nygata', completed: false },
{ name: 'Nygård terrasse', completed: false },
{ name: 'Nygårds allé', completed: false },
{ name: 'Nygårdsveien', completed: false },
{ name: 'Nyjordeveien', completed: false },
{ name: 'Nyjordstubben', completed: false },
{ name: 'Nylandsveien', completed: false },
{ name: 'Nylænde', completed: false },
{ name: 'Nypeveien', completed: false },
{ name: 'Nyquistveien', completed: false },
{ name: 'Nøklesvingen', completed: false },
{ name: 'Nøklevannsveien', completed: false },
{ name: 'Nøkleveien', completed: false },
{ name: 'Nøttekneika', completed: false },
{ name: 'Nøtteveien', completed: false },
{ name: 'Nåkkves vei', completed: false },
{ name: 'Oberst Angells vei', completed: false },
{ name: 'Oberst Rodes vei', completed: false },
{ name: 'Observatorie terrasse', completed: false },
{ name: 'Observatoriegata', completed: false },
{ name: 'Odalsgata', completed: false },
{ name: 'Odd Hassels vei', completed: false },
{ name: 'Odins gate', completed: false },
{ name: 'Odvar Solbergs vei', completed: false },
{ name: 'Ogmunds vei', completed: false },
{ name: 'Ola Narr', completed: false },
{ name: 'Olaf Bulls vei', completed: false },
{ name: 'Olaf Helsets vei', completed: false },
{ name: 'Olaf Lørenskogens vei', completed: false },
{ name: 'Olaf Ryes plass', completed: false },
{ name: 'Olaf Schous vei', completed: true },
{ name: 'Olafiagangen', completed: false },
{ name: 'Olasrudveien', completed: false },
{ name: 'Olaus Fjørtofts vei', completed: false },
{ name: 'Olav Aukrusts vei', completed: false },
{ name: 'Olav Bjaalands vei', completed: false },
{ name: 'Olav Hegnas vei', completed: true },
{ name: 'Olav Kyrres gate', completed: false },
{ name: 'Olav Kyrres plass', completed: false },
{ name: 'Olav M. Troviks vei', completed: false },
{ name: 'Olav Nygards veg', completed: false },
{ name: 'Olav Selvaags plass', completed: false },
{ name: 'Olav Vs gate', completed: false },
{ name: 'Olav Vs plass', completed: false },
{ name: 'Olaves Hvervens vei', completed: false },
{ name: 'Oldtidsveien', completed: false },
{ name: 'Ole Brumms vei', completed: false },
{ name: 'Ole Bulls gate', completed: true },
{ name: 'Ole Deviks vei', completed: false },
{ name: 'Ole Fladagers gate', completed: false },
{ name: 'Ole Jacob Brochs gate', completed: false },
{ name: 'Ole Messelts vei', completed: false },
{ name: 'Ole Moes vei', completed: false },
{ name: 'Ole Reistads vei', completed: false },
{ name: 'Ole Vigs gate', completed: false },
{ name: 'Ole Wingers vei', completed: false },
{ name: 'Olleveien', completed: false },
{ name: 'Olsens Enkes vei', completed: false },
{ name: 'Oluf Onsums vei', completed: false },
{ name: 'Ombergveien', completed: false },
{ name: 'Omsens gate', completed: true },
{ name: 'Operagata', completed: false },
{ name: 'Oppegårdgata', completed: false },
{ name: 'Opplandgata', completed: false },
{ name: 'Oppsal terrasse', completed: false },
{ name: 'Oppsalstubben', completed: false },
{ name: 'Oppsaltoppen', completed: false },
{ name: 'Oppsalveien', completed: false },
{ name: 'Oreliveien', completed: false },
{ name: 'Orionveien', completed: false },
{ name: 'Orknøygata', completed: false },
{ name: 'Ormsundbakken', completed: false },
{ name: 'Ormsundveien', completed: false },
{ name: 'Ormøybakken', completed: false },
{ name: 'Orrebakken', completed: false },
{ name: 'Orreskogen', completed: false },
{ name: 'Orreveien', completed: false },
{ name: 'Oscar Dahls vei', completed: false },
{ name: 'Oscars gate', completed: false },
{ name: 'Oscarshallveien', completed: false },
{ name: 'Oskar Braatens gate', completed: true },
{ name: 'Oskar Braatens plass', completed: true },
{ name: 'Oslo gate', completed: false },
{ name: 'Oslo torg', completed: false },
{ name: 'Ospeskogveien', completed: false },
{ name: 'Ospestien', completed: false },
{ name: 'Ostadalsveien', completed: false },
{ name: 'Osterhaus gate', completed: false },
{ name: 'Othilie Tonnings vei', completed: false },
{ name: 'Ottar Birtings gate', completed: false },
{ name: 'Otto Blehrs vei', completed: false },
{ name: 'Otto Sogns vei', completed: false },
{ name: 'Otto Sverdrups gate', completed: false },
{ name: 'Ove Kristiansens vei', completed: false },
{ name: 'P.A. Holms vei', completed: false },
{ name: 'P.A. Munchs vei', completed: false },
{ name: 'P.T. Mallings vei', completed: false },
{ name: 'Pans vei', completed: false },
{ name: 'Pareliusveien', completed: false },
{ name: 'Parkdammen', completed: false },
{ name: 'Parkenga', completed: false },
{ name: 'Parkveien', completed: false },
{ name: 'Pasoplia', completed: false },
{ name: 'Pasopsvingen', completed: false },
{ name: 'Pasopveien', completed: false },
{ name: 'Pastor Blaauws vei', completed: false },
{ name: 'Pastor Fangens vei', completed: false },
{ name: 'Paulus plass', completed: false },
{ name: 'Pavels gate', completed: false },
{ name: 'Pearl Engens vei', completed: false },
{ name: 'Peder Ankers plass', completed: false },
{ name: 'Peder Ankers vei', completed: false },
{ name: 'Peder Claussøns gate', completed: false },
{ name: 'Peder Grøns vei', completed: false },
{ name: 'Peder Holters vei', completed: false },
{ name: 'Per Gynts vei', completed: false },
{ name: 'Per Krohgs vei', completed: false },
{ name: 'Per Kvibergs gate', completed: true },
{ name: 'Pernilles vei', completed: false },
{ name: 'Persbråtan', completed: false },
{ name: 'Persveien', completed: false },
{ name: 'Peter Møllers vei', completed: true },
{ name: 'Petersborgplassen', completed: false },
{ name: 'Peter Aas vei', completed: false },
{ name: 'Pilestien', completed: false },
{ name: 'Pilestredet', completed: false },
{ name: 'Pilestredet park', completed: false },
{ name: 'Pilotveien', completed: false },
{ name: 'Pilveien', completed: false },
{ name: 'Pionerstien', completed: false },
{ name: 'Planetveien', completed: false },
{ name: 'Platous gate', completed: false },
{ name: 'Platåveien', completed: false },
{ name: 'Plogveien', completed: false },
{ name: 'Pløens gate', completed: false },
{ name: 'Pontoppidans gate', completed: false },
{ name: 'Poppelstien', completed: false },
{ name: 'Porfyrveien', completed: false },
{ name: 'Postdamveien', completed: false },
{ name: 'Pottemakerveien', completed: false },
{ name: 'President Harbitz gate', completed: false },
{ name: 'Presidentgata', completed: true },
{ name: 'Prestegata', completed: false },
{ name: 'Prestegårdsveien', completed: false },
{ name: 'Prestlia', completed: false },
{ name: 'Prinsdalsfaret', completed: false },
{ name: 'Prinsdalskleiva', completed: false },
{ name: 'Prinsens gate', completed: false },
{ name: 'Prinsessealléen', completed: false },
{ name: 'Prinsessens plass', completed: false },
{ name: 'Priorveien', completed: false },
{ name: 'Problemveien', completed: false },
{ name: 'Professor Aschehougs plass', completed: false },
{ name: 'Professor Birkelands vei', completed: false },
{ name: 'Professor Dahls gate', completed: false },
{ name: 'Professor Lochmanns gate', completed: false },
{ name: 'Promenaden', completed: false },
{ name: 'Prost Hallings vei', completed: false },
{ name: 'Protonveien', completed: false },
{ name: 'Putti Westerdals plass', completed: false },
{ name: 'Paal Bergs vei', completed: false },
{ name: 'Rabakken', completed: false },
{ name: 'Rabbeveien', completed: false },
{ name: 'Radarveien', completed: false },
{ name: 'Radioveien', completed: false },
{ name: 'Ragna Maries vei', completed: false },
{ name: 'Ragna Nielsens vei', completed: false },
{ name: 'Ragnar Kalheims plass', completed: false },
{ name: 'Ragnfrids vei', completed: false },
{ name: 'Ragnhild Schibbyes vei', completed: false },
{ name: 'Ranunkelveien', completed: false },
{ name: 'Rappveien', completed: false },
{ name: 'Raschs vei', completed: false },
{ name: 'Rasmus Engers vei', completed: false },
{ name: 'Rasmus Winderens vei', completed: false },
{ name: 'Rasmusbakken', completed: false },
{ name: 'Rathkes gate', completed: false },
{ name: 'Rathkes plass', completed: false },
{ name: 'Raukveien', completed: false },
{ name: 'Ravnkollbakken', completed: false },
{ name: 'Ravnkroken', completed: false },
{ name: 'Ravnåsveien', completed: false },
{ name: 'Refstad allé', completed: true },
{ name: 'Refstadsvingen', completed: false },
{ name: 'Refstadveien', completed: false },
{ name: 'Regnbueveien', completed: false },
{ name: 'Reichweins gate', completed: false },
{ name: 'Reidar Kobros vei', completed: false },
{ name: 'Reina', completed: false },
{ name: 'Rektorhaugen', completed: false },
{ name: 'Revefaret', completed: false },
{ name: 'Revierstredet', completed: false },
{ name: 'Ribbunggata', completed: false },
{ name: 'Ribstonveien', completed: true },
{ name: 'Riddervolds gate', completed: false },
{ name: 'Riddervolds plass', completed: false },
{ name: 'Rideveien', completed: false },
{ name: 'Riflegata', completed: false },
{ name: 'Riggergangen', completed: false },
{ name: 'Rigmor Luihns vei', completed: false },
{ name: 'Rimveien', completed: false },
{ name: 'Ringgata', completed: false },
{ name: 'Ringnesveien', completed: false },
{ name: 'Ringshusstubben', completed: false },
{ name: 'Ringshusveien', completed: false },
{ name: 'Ris skolevei', completed: false },
{ name: 'Risalléen', completed: false },
{ name: 'Risbakken', completed: false },
{ name: 'Risbekkveien', completed: false },
{ name: 'Risløkkalléen', completed: false },
{ name: 'Risløkkfaret', completed: false },
{ name: 'Risløkkveien', completed: false },
{ name: 'Risstubben', completed: false },
{ name: 'Risveien', completed: false },
{ name: 'Roald Amundsens gate', completed: false },
{ name: 'Robert Levins gate', completed: false },
{ name: 'Robert Millars vei', completed: false },
{ name: 'Rodes plass', completed: true },
{ name: 'Rognerudveien', completed: false },
{ name: 'Rolf E. Stenersens allé', completed: false },
{ name: 'Rolf Hofmos gate', completed: false },
{ name: 'Rolf Hofmos plass', completed: false },
{ name: 'Rolf Strangers plass', completed: false },
{ name: 'Rolf Wickstrøms vei', completed: false },
{ name: 'Rommibakken', completed: false },
{ name: 'Rommiskogen', completed: false },
{ name: 'Romsdalsgata', completed: false },
{ name: 'Romsås senter', completed: false },
{ name: 'Romsåsveien', completed: false },
{ name: 'Rosenbergveien', completed: false },
{ name: 'Rosenborggata', completed: false },
{ name: 'Rosendalsveien', completed: false },
{ name: 'Rosenhoffgata', completed: true },
{ name: 'Rosenholmveien', completed: false },
{ name: 'Rosenkrantz gate', completed: false },
{ name: 'Rosenlundgata', completed: true },
{ name: 'Roseveien', completed: true },
{ name: 'Rosings gate', completed: false },
{ name: 'Rosteds gate', completed: false },
{ name: 'Rostockgata', completed: false },
{ name: 'Roveruds gate', completed: true },
{ name: 'Rubina Ranas gate', completed: false },
{ name: 'Rudolf Nilsens plass', completed: false },
{ name: 'Rugdeberget', completed: false },
{ name: 'Rugdestien', completed: false },
{ name: 'Rugdeveien', completed: false },
{ name: 'Rugveien', completed: false },
{ name: 'Rundhaugveien', completed: false },
{ name: 'Rundingen', completed: false },
{ name: 'Rundmyrveien', completed: false },
{ name: 'Rundtjernveien', completed: false },
{ name: 'Ruseløkkveien', completed: false },
{ name: 'Rustadgrenda', completed: false },
{ name: 'Rustadsaga', completed: false },
{ name: 'Ruth Reeses plass', completed: false },
{ name: 'Ruths vei', completed: false },
{ name: 'Ryenbergveien', completed: false },
{ name: 'Ryenstubben', completed: false },
{ name: 'Ryensvingen', completed: false },
{ name: 'Ryghs vei', completed: false },
{ name: 'Røa terrasse', completed: false },
{ name: 'Røahagan', completed: false },
{ name: 'Røahellinga', completed: false },
{ name: 'Røaknekken', completed: false },
{ name: 'Røatoppen', completed: false },
{ name: 'Røaveien', completed: false },
{ name: 'Rødbergveien', completed: false },
{ name: 'Rødbråtbakken', completed: false },
{ name: 'Rødkleivfaret', completed: false },
{ name: 'Rødstuveien', completed: true },
{ name: 'Rødtvetkroken', completed: false },
{ name: 'Rødtvetveien', completed: false },
{ name: 'Røhrts vei', completed: false },
{ name: 'Rønningveien', completed: false },
{ name: 'Røslyngveien', completed: false },
{ name: 'Rådhusgata', completed: false },
{ name: 'Rådhusplassen', completed: false },
{ name: 'Rådyrlia', completed: false },
{ name: 'Rådyrstien', completed: false },
{ name: 'Rådyrveien', completed: false },
{ name: 'S.H. Lundhs vei', completed: false },
{ name: 'Sagadammen', completed: false },
{ name: 'Sagstukroken', completed: false },
{ name: 'Sagstuveien', completed: false },
{ name: 'Sagveien', completed: false },
{ name: 'Sakariasveien', completed: false },
{ name: 'Salm. Marius Jantzens plass', completed: false },
{ name: 'Sam Eydes vei', completed: false },
{ name: 'Samvirkeveien', completed: false },
{ name: 'Sanatoriebakken', completed: false },
{ name: 'Sandakerveien', completed: true },
{ name: 'Sandefjordgata', completed: false },
{ name: 'Sandermosveien', completed: false },
{ name: 'Sandstuveien', completed: false },
{ name: 'Sandtakveien', completed: false },
{ name: 'Sandåsveien', completed: false },
{ name: 'Sannergata', completed: true },
{ name: 'Sarabråtveien', completed: false },
{ name: 'Sarpsborggata', completed: false },
{ name: 'Sars gate', completed: false },
{ name: 'Saturnveien', completed: false },
{ name: 'Saxegaardsgata', completed: false },
{ name: 'Schandorffs plass', completed: false },
{ name: 'Schives gate', completed: false },
{ name: 'Schiøtts vei', completed: false },
{ name: 'Schleppegrells gate', completed: false },
{ name: 'Schous plass', completed: false },
{ name: 'Schouterrassen', completed: true },
{ name: 'Schultz gate', completed: false },
{ name: 'Schwachs gate', completed: false },
{ name: 'Schweigaards gate', completed: false },
{ name: 'Schwensens gate', completed: false },
{ name: 'Schübelers gate', completed: false },
{ name: 'Schæffers gate', completed: false },
{ name: 'Schønings gate', completed: false },
{ name: 'Sehesteds gate', completed: false },
{ name: 'Seilduksgata', completed: false },
{ name: 'Seljeveien', completed: true },
{ name: 'Selma Ellefsens vei', completed: false },
{ name: 'Selvbyggerveien', completed: false },
{ name: 'Sem Sælands vei', completed: false },
{ name: 'Seterbråtveien', completed: false },
{ name: 'Seterhøybakken', completed: false },
{ name: 'Seterhøyveien', completed: false },
{ name: 'Seterliveien', completed: false },
{ name: 'Seterveien', completed: false },
{ name: 'Setervollveien', completed: false },
{ name: 'Setra vei', completed: false },
{ name: 'Sexes gate', completed: false },
{ name: 'Sidsel Sidsærks vei', completed: false },
{ name: 'Siebkes gate', completed: false },
{ name: 'Sigbjørn Obstfelders vei', completed: false },
{ name: 'Siggerudveien', completed: false },
{ name: 'Signe Nesbakkens vei', completed: false },
{ name: 'Sigrid Undsets vei', completed: false },
{ name: 'Sigurd Astrups vei', completed: false },
{ name: 'Sigurd Hoels vei', completed: true },
{ name: 'Sigurd A. Danielsens vei', completed: false },
{ name: 'Sigurd Iversens vei', completed: false },
{ name: 'Sigurd Johannesens vei', completed: false },
{ name: 'Sigurd Lies gate', completed: true },
{ name: 'Sigurd Syrs gate', completed: false },
{ name: 'Sigurd Weisæths vei', completed: false },
{ name: 'Sigurds gate', completed: false },
{ name: 'Sigyns gate', completed: false },
{ name: 'Siksakveien', completed: false },
{ name: 'Siloveien', completed: true },
{ name: 'Silurveien', completed: false },
{ name: 'Simensbrekka', completed: false },
{ name: 'Simensbråtveien', completed: false },
{ name: 'Simon Darres vei', completed: false },
{ name: 'Singasteinveien', completed: false },
{ name: 'Sinsenterrassen', completed: true },
{ name: 'Sinsenveien', completed: true },
{ name: 'Siriusveien', completed: false },
{ name: 'Sisikveien', completed: false },
{ name: 'Sjursøya', completed: false },
{ name: 'Sjøgangen', completed: false },
{ name: 'Sjøgata', completed: false },
{ name: 'Sjølyst plass', completed: false },
{ name: 'Sjølystveien', completed: false },
{ name: 'Skabos vei', completed: false },
{ name: 'Skansen terrasse', completed: false },
{ name: 'Skansestien', completed: false },
{ name: 'Skanseveien', completed: false },
{ name: 'Skappels vei', completed: false },
{ name: 'Skarpsnogata', completed: false },
{ name: 'Skarskroken', completed: false },
{ name: 'Skausnaret', completed: false },
{ name: 'Skedsmogata', completed: false },
{ name: 'Skibakkeveien', completed: false },
{ name: 'Skiferveien', completed: false },
{ name: 'Skigardveien', completed: false },
{ name: 'Skillebekk', completed: false },
{ name: 'Skinnerbakken', completed: false },
{ name: 'Skippergata', completed: false },
{ name: 'Skjelderups gate', completed: false },
{ name: 'Skjellveien', completed: false },
{ name: 'Skjerstadveien', completed: false },
{ name: 'Skjoldveien', completed: false },
{ name: 'Skjønnhaugveien', completed: false },
{ name: 'Skogbakken', completed: false },
{ name: 'Skogbrynet', completed: false },
{ name: 'Skogfaret', completed: false },
{ name: 'Skogholtveien', completed: false },
{ name: 'Skogroveien', completed: false },
{ name: 'Skogryggveien', completed: false },
{ name: 'Skogstien', completed: false },
{ name: 'Skogvollveien', completed: false },
{ name: 'Skolebakken', completed: false },
{ name: 'Skolestien', completed: false },
{ name: 'Skoleveien', completed: false },
{ name: 'Skovveien', completed: false },
{ name: 'Skrabben', completed: false },
{ name: 'Skrenten', completed: false },
{ name: 'Skråninga', completed: false },
{ name: 'Skullerudbakken', completed: false },
{ name: 'Skullerudskogen', completed: false },
{ name: 'Skullerudstubben', completed: false },
{ name: 'Skullerudveien', completed: false },
{ name: 'Skuronnveien', completed: false },
{ name: 'Skøyen allé', completed: false },
{ name: 'Skøyen terrasse', completed: false },
{ name: 'Skøyen torg', completed: false },
{ name: 'Skøyenbakken', completed: false },
{ name: 'Skøyenbrynet', completed: false },
{ name: 'Skøyenkneika', completed: false },
{ name: 'Skøyenkroken', completed: false },
{ name: 'Skøyenstubben', completed: false },
{ name: 'Skøyensvingen', completed: false },
{ name: 'Skøyenveien', completed: false },
{ name: 'Skøyenåsveien', completed: false },
{ name: 'Skådalsveien', completed: false },
{ name: 'Slalåmveien', completed: false },
{ name: 'Sledemeien', completed: false },
{ name: 'Slemdalsveien', completed: false },
{ name: 'Slemdalsvingen', completed: false },
{ name: 'Sletteløkka', completed: false },
{ name: 'Slettenveien', completed: false },
{ name: 'Slimeveien', completed: false },
{ name: 'Slireveien', completed: false },
{ name: 'Sloreåsen', completed: false },
{ name: 'Slottsplassen', completed: false },
{ name: 'Slyngveien', completed: false },
{ name: 'Slåmotgangen', completed: false },
{ name: 'Slåtteveien', completed: false },
{ name: 'Smalgangen', completed: false },
{ name: 'Smalvollveien', completed: false },
{ name: 'Smalåkeren', completed: false },
{ name: 'Smed Kjeldsens vei', completed: false },
{ name: 'Smedbergveien', completed: false },
{ name: 'Smedgata', completed: false },
{ name: 'Smedhaugen', completed: false },
{ name: 'Smedkroken', completed: false },
{ name: 'Smedstusvingen', completed: false },
{ name: 'Smeltedigelen', completed: false },
{ name: 'Smestadhagan', completed: false },
{ name: 'Smestadveien', completed: false },
{ name: 'Smedveien', completed: false },
{ name: 'Smithsvingen', completed: false },
{ name: 'Smiuvegen', completed: false },
{ name: 'Smålensgata', completed: false },
{ name: 'Snargangen', completed: false },
{ name: 'Snipemyrlia', completed: false },
{ name: 'Snipemyrveien', completed: false },
{ name: 'Snipp-Møllers vei', completed: false },
{ name: 'Snippen', completed: true },
{ name: 'Snorres gate', completed: false },
{ name: 'Snøggveien', completed: false },
{ name: 'Soelvolds vei', completed: false },
{ name: 'Sofienberggata', completed: false },
{ name: 'Sofies gate', completed: false },
{ name: 'Sofies plass', completed: false },
{ name: 'Sogneprest Asle Engers plass', completed: false },
{ name: 'Sognsvannsveien', completed: false },
{ name: 'Sognsveien', completed: false },
{ name: 'Solbakken allé', completed: false },
{ name: 'Solbakkeveien', completed: true },
{ name: 'Solbergliveien', completed: false },
{ name: 'Solefallsveien', completed: false },
{ name: 'Soleglad', completed: false },
{ name: 'Solemskogveien', completed: false },
{ name: 'Solfjellsbakken', completed: false },
{ name: 'Solfjellshøgda', completed: false },
{ name: 'Solhauggata', completed: true },
{ name: 'Solheimgata', completed: false },
{ name: 'Solkroken', completed: false },
{ name: 'Sollerudveien', completed: false },
{ name: 'Solligata', completed: false },
{ name: 'Solligrenda', completed: false },
{ name: 'Solli plass', completed: false },
{ name: 'Solskinnskroken', completed: false },
{ name: 'Solskinnsveien', completed: false },
{ name: 'Solstugrenda', completed: false },
{ name: 'Solvangveien', completed: false },
{ name: 'Solveien', completed: false },
{ name: 'Solørgata', completed: false },
{ name: 'Solåsveien', completed: false },
{ name: 'Sommerrogata', completed: false },
{ name: 'Sondrevegen', completed: false },
{ name: 'Sonja Henies plass', completed: false },
{ name: 'Sons gate', completed: false },
{ name: 'Sophus Bugges plass', completed: false },
{ name: 'Sophus Lies gate', completed: false },
{ name: 'Sophus Aars vei', completed: false },
{ name: 'Sorgenfrigata', completed: false },
{ name: 'Spektrumveien', completed: false },
{ name: 'Spikerveien', completed: true },
{ name: 'Spiralen', completed: false },
{ name: 'Spireaveien', completed: true },
{ name: 'Sponhoggbakken', completed: false },
{ name: 'Sponhoggveien', completed: false },
{ name: 'Sponstugrenda', completed: false },
{ name: 'Sponstuveien', completed: false },
{ name: 'Sportsstien', completed: false },
{ name: 'Sportsveien', completed: false },
{ name: 'Sporveisgata', completed: false },
{ name: 'Spångbergveien', completed: false },
{ name: 'St. Edmunds vei', completed: false },
{ name: 'St. Georgs vei', completed: false },
{ name: 'St. Halvards gate', completed: false },
{ name: 'St. Halvards plass', completed: false },
{ name: 'St. Jørgens vei', completed: true },
{ name: 'St. Olavs gate', completed: false },
{ name: 'St. Olavs plass', completed: false },
{ name: 'St. Sunnivas gate', completed: false },
{ name: 'Staffeldts gate', completed: false },
{ name: 'Stalheimbakken', completed: false },
{ name: 'Stallerudveien', completed: false },
{ name: 'Stamhusveien', completed: false },
{ name: 'Standardveien', completed: false },
{ name: 'Stanseveien', completed: false },
{ name: 'Starveien', completed: false },
{ name: 'Stasjonsallmenningen', completed: false },
{ name: 'Stasjonsveien', completed: false },
{ name: 'Statsråd Mathiesens vei', completed: false },
{ name: 'Staudeveien', completed: false },
{ name: 'Stavangergata', completed: false },
{ name: 'Steenstrups gate', completed: false },
{ name: 'Steinbakken', completed: false },
{ name: 'Steinborgveien', completed: true },
{ name: 'Steingardveien', completed: false },
{ name: 'Steingrims vei', completed: false },
{ name: 'Steinhammerveien', completed: false },
{ name: 'Steinliveien', completed: false },
{ name: 'Steinspranget', completed: false },
{ name: 'Stenbråtveien', completed: false },
{ name: 'Stenersgata', completed: false },
{ name: 'Stensberggata', completed: false },
{ name: 'Stensgata', completed: false },
{ name: 'Stensrudlia', completed: false },
{ name: 'Stensrudåsveien', completed: false },
{ name: 'Stien', completed: false },
{ name: 'Stiftamtmann Kaas vei', completed: false },
{ name: 'Stigenga', completed: false },
{ name: 'Stigengbakken', completed: false },
{ name: 'Stjerneblokkveien', completed: false },
{ name: 'Stjernemyrveien', completed: false },
{ name: 'Stjerneveien', completed: false },
{ name: 'Stockfleths gate', completed: false },
{ name: 'Stockholmgata', completed: true },
{ name: 'Stolmakergata', completed: false },
{ name: 'Stordamsnaret', completed: false },
{ name: 'Stordamveien', completed: false },
{ name: 'Storgata', completed: false },
{ name: 'Storkenebbveien', completed: false },
{ name: 'Stormyrveien', completed: false },
{ name: 'Storoveien', completed: false },
{ name: 'Stortingsgata', completed: false },
{ name: 'Stortings plass', completed: false },
{ name: 'Stortorvet', completed: false },
{ name: 'Storåssvingen', completed: false },
{ name: 'Storåsveien', completed: false },
{ name: 'Stovner senter', completed: false },
{ name: 'Stovnerbakken', completed: false },
{ name: 'Stovnerfaret', completed: false },
{ name: 'Stovnerlia', completed: false },
{ name: 'Stovnerveien', completed: false },
{ name: 'Stranden', completed: false },
{ name: 'Strandgata', completed: false },
{ name: 'Strandhaugen', completed: false },
{ name: 'Strandpromenaden', completed: false },
{ name: 'Strømsborgveien', completed: false },
{ name: 'Strømsbråtaveien', completed: false },
{ name: 'Strømstadgata', completed: true },
{ name: 'Strømsveien', completed: true },
{ name: 'Stubben (Vestre Aker)', completed: false },
{ name: 'Stubberudveien', completed: false },
{ name: 'Sturlas vei', completed: false },
{ name: 'Stuttvegen', completed: false },
{ name: 'Styggdalen', completed: false },
{ name: 'Stølsvegen', completed: false },
{ name: 'Støperigata', completed: false },
{ name: 'Støttumveien', completed: false },
{ name: 'Stålfjæra', completed: false },
{ name: 'Stålverkskroken', completed: true },
{ name: 'Stålverksveien', completed: true },
{ name: 'Stårputtveien', completed: false },
{ name: 'Suhms gate', completed: false },
{ name: 'Sukkertoppveien', completed: true },
{ name: 'Sundveien', completed: false },
{ name: 'Sunnmørgata', completed: false },
{ name: 'Surstoffveien', completed: true },
{ name: 'Svalbardveien', completed: false },
{ name: 'Svaleveien', completed: false },
{ name: 'Svaneveien', completed: false },
{ name: 'Svartdalsveien', completed: true },
{ name: 'Svartorseterveien', completed: false },
{ name: 'Svarttrostveien', completed: false },
{ name: 'Sveavegen', completed: false },
{ name: 'Sveiserveien', completed: false },
{ name: 'Sveiva', completed: false },
{ name: 'Sven Bruns gate', completed: false },
{ name: 'Sven Oftedals vei', completed: false },
{ name: 'Svensenga', completed: false },
{ name: 'Svenskerudveien', completed: false },
{ name: 'Svenskestien', completed: false },
{ name: 'Svenstuveien', completed: false },
{ name: 'Sverdrups gate', completed: false },
{ name: 'Sveriges gate', completed: false },
{ name: 'Sverre Enevolds plass', completed: false },
{ name: 'Sverre Iversens vei', completed: false },
{ name: 'Sverre Refstads plass', completed: false },
{ name: 'Sverres gate', completed: false },
{ name: 'Sverres plass', completed: true },
{ name: 'Sverrestien', completed: false },
{ name: 'Svingen', completed: false },
{ name: 'Svingen terrasse', completed: false },
{ name: 'Svinten', completed: false },
{ name: 'Svoldergata', completed: false },
{ name: 'Svovelstikka', completed: true },
{ name: 'Symreveien', completed: false },
{ name: 'Syrenveien', completed: false },
{ name: 'Sæveruds plass', completed: true },
{ name: 'Sølve Solfengs vei', completed: false },
{ name: 'Sømveien', completed: false },
{ name: 'Søndre gate', completed: false },
{ name: 'Søndre Rød', completed: false },
{ name: 'Sørbråtveien', completed: false },
{ name: 'Sørbyhaugen', completed: false },
{ name: 'Søren Bulls vei', completed: false },
{ name: 'Søren Jaabæks gate', completed: false },
{ name: 'Sørengkaia', completed: false },
{ name: 'Sørhellinga', completed: false },
{ name: 'Sørkedalen', completed: false },
{ name: 'Sørkedalsveien', completed: false },
{ name: 'Sørligata', completed: false },
{ name: 'Sørli plass', completed: false },
{ name: 'Sørlibakken', completed: false },
{ name: 'Sørlistien', completed: false },
{ name: 'Sørliveien', completed: false },
{ name: 'Sørsletta', completed: false },
{ name: 'Sørstien', completed: false },
{ name: 'Sørsvingen', completed: false },
{ name: 'Sørumgata', completed: false },
{ name: 'Sørvangen', completed: false },
{ name: 'Tallbergveien', completed: false },
{ name: 'Tamburveien', completed: true },
{ name: 'Tangenveien', completed: false },
{ name: 'Tangerudbakken', completed: false },
{ name: 'Tangerudveien', completed: false },
{ name: 'Tante Ulrikkes vei', completed: false },
{ name: 'Teatergata', completed: false },
{ name: 'Teaterplassen', completed: false },
{ name: 'Teglverksgata', completed: true },
{ name: 'Teglverkskroken', completed: true },
{ name: 'Teisenveien', completed: false },
{ name: 'Telavåggata', completed: false },
{ name: 'Telemarksvingen', completed: false },
{ name: 'Telesvingen', completed: false },
{ name: 'Telthusbakken', completed: false },
{ name: 'Tennisveien', completed: false },
{ name: 'Teppaveien', completed: false },
{ name: 'Terminalveien', completed: false },
{ name: 'Terneveien', completed: true },
{ name: 'Terningbekk', completed: false },
{ name: 'Terrasseveien', completed: false },
{ name: 'Tertitten', completed: false },
{ name: 'Tevlingveien', completed: false },
{ name: 'Th. Kittelsens vei', completed: false },
{ name: 'Thaulows vei', completed: false },
{ name: 'Theodor Dahls vei', completed: false },
{ name: 'Theodor Kittelsens plass', completed: false },
{ name: 'Theodor Løvstads vei', completed: false },
{ name: 'Thereses gate', completed: false },
{ name: 'Thomas Heftyes gate', completed: false },
{ name: 'Thomas Heftyes plass', completed: false },
{ name: 'Thomas Ulvens vei', completed: false },
{ name: 'Thomles gate', completed: false },
{ name: 'Thor Olsens gate', completed: false },
{ name: 'Thorbjørn Egners plass', completed: false },
{ name: 'Thorleif Haugs vei', completed: false },
{ name: 'Thorleif Kleves torg', completed: false },
{ name: 'Thorleifs allé', completed: false },
{ name: 'Thorn Dønhaugs vei', completed: false },
{ name: 'Thorvald Erichsens vei', completed: false },
{ name: 'Thorvald Meyers gate', completed: false },
{ name: 'Thulstrups gate', completed: false },
{ name: 'Thunes vei', completed: false },
{ name: 'Thurmanns gate', completed: false },
{ name: 'Thygesons vei', completed: false },
{ name: 'Tidemands gate', completed: false },
{ name: 'Tingstuveien', completed: false },
{ name: 'Tiriltunga', completed: false },
{ name: 'Tirilveien', completed: false },
{ name: 'Tittutgrenda', completed: false },
{ name: 'Tittutveien', completed: false },
{ name: 'Tiurveien', completed: false },
{ name: 'Tjernfaret', completed: false },
{ name: 'Tjernlia', completed: false },
{ name: 'Tjernveien', completed: false },
{ name: 'Tjonerudbakken', completed: false },
{ name: 'Tjonerudveien', completed: false },
{ name: 'Tjuvholmen', completed: false },
{ name: 'Tjuvholmen allé', completed: false },
{ name: 'Toftdahls vei', completed: false },
{ name: 'Toftes gate', completed: false },
{ name: 'Tokerudbekken', completed: false },
{ name: 'Tokerudberget', completed: false },
{ name: 'Tokeruddalen', completed: false },
{ name: 'Tollbugata', completed: false },
{ name: 'Tomm Murstadbakken', completed: false },
{ name: 'Tonsenveien', completed: false },
{ name: 'Toppen', completed: false },
{ name: 'Toppåsveien', completed: false },
{ name: 'Tor Jonssons veg', completed: false },
{ name: 'Torbjørns vei', completed: false },
{ name: 'Tordenskiolds gate', completed: false },
{ name: 'Tore Hals Mejdells vei', completed: false },
{ name: 'Tore Hunds vei', completed: true },
{ name: 'Tores vei', completed: false },
{ name: 'Torggata', completed: false },
{ name: 'Torgny Segerstedts vei', completed: false },
{ name: 'Torjusbakken', completed: false },
{ name: 'Tormod Knutsons vei', completed: false },
{ name: 'Tormods vei', completed: false },
{ name: 'Tors gate', completed: false },
{ name: 'Torsborgveien', completed: false },
{ name: 'Torshovgata', completed: true },
{ name: 'Torstølveien', completed: false },
{ name: 'Torvbakkgata', completed: false },
{ name: 'Tostrup terrasse', completed: false },
{ name: 'Tostrups gate', completed: false },
{ name: 'Totengata', completed: false },
{ name: 'Traktorveien', completed: true },
{ name: 'Traneveien', completed: true },
{ name: 'Trappa', completed: false },
{ name: 'Trasoppterrassen', completed: false },
{ name: 'Trasoppveien', completed: false },
{ name: 'Traverveien', completed: false },
{ name: 'Trekanten', completed: false },
{ name: 'Trelastgata', completed: false },
{ name: 'Treschows gate', completed: true },
{ name: 'Treskeveien', completed: false },
{ name: 'Trestegveien', completed: false },
{ name: 'Trettebakken', completed: false },
{ name: 'Trimmen', completed: false },
{ name: 'Trimveien', completed: false },
{ name: 'Trolldalsveien', completed: false },
{ name: 'Trollfaret', completed: false },
{ name: 'Trollstien', completed: false },
{ name: 'Trollsvingen', completed: false },
{ name: 'Trollvannsveien', completed: false },
{ name: 'Trollåsveien', completed: false },
{ name: 'Tromsøgata', completed: false },
{ name: 'Trondheimsveien', completed: false },
{ name: 'Trostefaret', completed: false },
{ name: 'Trosterudstien', completed: false },
{ name: 'Trosterudveien', completed: false },
{ name: 'Trudvangveien', completed: false },
{ name: 'Trygve Lies plass', completed: false },
{ name: 'Trygve Nilsens vei', completed: false },
{ name: 'Trygve Ryens vei', completed: false },
{ name: 'Trygve Strømbergs vei', completed: false },
{ name: 'Tryms vei', completed: false },
{ name: 'Trysilgata', completed: false },
{ name: 'Tryvannsveien', completed: false },
{ name: 'Trøndergata', completed: true },
{ name: 'Tråkka', completed: false },
{ name: 'Tuengen allé', completed: false },
{ name: 'Tuengveien', completed: false },
{ name: 'Tullinløkka', completed: false },
{ name: 'Tullins gate', completed: false },
{ name: 'Tunfaret', completed: false },
{ name: 'Tungebråtveien', completed: false },
{ name: 'Tunveien', completed: false },
{ name: 'Turbinveien', completed: false },
{ name: 'Turterfaret', completed: false },
{ name: 'Tusentrippen', completed: false },
{ name: 'Tussefaret', completed: false },
{ name: 'Tverrbakken', completed: true },
{ name: 'Tverrvei 2', completed: false },
{ name: 'Tverrvei 3', completed: false },
{ name: 'Tverrvei 4', completed: false },
{ name: 'Tvetenveien', completed: false },
{ name: 'Tyribakken', completed: false },
{ name: 'Tyrihansveien', completed: false },
{ name: 'Tyristubbveien', completed: false },
{ name: 'Tyskestrandveien', completed: false },
{ name: 'Tyslevkroken', completed: false },
{ name: 'Tyslevveien', completed: false },
{ name: 'Tønsberggata', completed: false },
{ name: 'Tørtbergveien', completed: false },
{ name: 'Tøyenbekken', completed: false },
{ name: 'Tøyengata', completed: false },
{ name: 'Tårngata', completed: false },
{ name: 'Tårnveien', completed: false },
{ name: 'Tåsen allé', completed: false },
{ name: 'Tåsen terrase', completed: false },
{ name: 'Tåsenveien', completed: false },
{ name: 'Uelands gate', completed: false },
{ name: 'Ugleveien', completed: false },
{ name: 'Ulfstens gate', completed: false },
{ name: 'Ullagerveien', completed: true },
{ name: 'Ullensakergata', completed: false },
{ name: 'Ullern allé', completed: false },
{ name: 'Ullern Gårds vei', completed: false },
{ name: 'Ullernchausseen', completed: false },
{ name: 'Ullernfaret', completed: false },
{ name: 'Ullernkammen', completed: false },
{ name: 'Ullernkollen', completed: false },
{ name: 'Ullernveien', completed: false },
{ name: 'Ullevålsalléen', completed: false },
{ name: 'Ullevålseterveien', completed: false },
{ name: 'Ullevålsveien', completed: false },
{ name: 'Ullveien', completed: false },
{ name: 'Ulsholtstien', completed: false },
{ name: 'Ulsholtveien', completed: false },
{ name: 'Ulsrudfaret', completed: false },
{ name: 'Ulsrudkollen', completed: false },
{ name: 'Ulsrudveien', completed: false },
{ name: 'Ulvengfaret', completed: false },
{ name: 'Ulvenveien', completed: false },
{ name: 'Underhaugsveien', completed: false },
{ name: 'Ungers gate', completed: false },
{ name: 'Universitetsgata', completed: false },
{ name: 'Universitetsplassen', completed: false },
{ name: 'Unnarennet', completed: false },
{ name: 'Uranienborg terrasse', completed: false },
{ name: 'Uranienborgveien', completed: false },
{ name: 'Urtegata', completed: false },
{ name: 'Urtestien', completed: false },
{ name: 'Ustvedts vei', completed: false },
{ name: 'Utfartsveien', completed: false },
{ name: 'Utmarkveien', completed: false },
{ name: 'Utsikten', completed: false },
{ name: 'Utsynsveien', completed: false },
{ name: 'Vahls gate', completed: false },
{ name: 'Valborgs vei', completed: false },
{ name: 'Valdresgata', completed: false },
{ name: 'Valhallveien', completed: false },
{ name: 'Valkyriegata', completed: false },
{ name: 'Valkyrie plass', completed: false },
{ name: 'Vallefaret', completed: true },
{ name: 'Vallegata', completed: false },
{ name: 'Valmueveien', completed: false },
{ name: 'Vangen', completed: false },
{ name: 'Vardeheimveien', completed: false },
{ name: 'Vardeveien', completed: false },
{ name: 'Vardøgata', completed: true },
{ name: 'Vargveien', completed: false },
{ name: 'Vaskeribakken', completed: false },
{ name: 'Vassfaret', completed: false },
{ name: 'Veita', completed: false },
{ name: 'Veitvetstubben', completed: false },
{ name: 'Veitvetsvingen', completed: false },
{ name: 'Veitvetveien', completed: false },
{ name: 'Vekslerveien', completed: true },
{ name: 'Veksthusfløtten', completed: false },
{ name: 'Vekterveien', completed: false },
{ name: 'Vendomveien', completed: false },
{ name: 'Vennerbakken', completed: false },
{ name: 'Vennerliveien', completed: false },
{ name: 'Vennersborgveien', completed: false },
{ name: 'Venåsvegen', completed: false },
{ name: 'Verkseier Furulunds vei', completed: false },
{ name: 'Verksgata', completed: true },
{ name: 'Verkstedveien', completed: false },
{ name: 'Veslefrikkveien', completed: false },
{ name: 'Veslekleiva', completed: false },
{ name: 'Veslekroken', completed: false },
{ name: 'Vesleveien', completed: false },
{ name: 'Vestagløttveien', completed: false },
{ name: 'Vestbrynet', completed: false },
{ name: 'Vestbysvingen', completed: false },
{ name: 'Vestbyveien', completed: false },
{ name: 'Vestengsvingen', completed: false },
{ name: 'Vestengveien', completed: false },
{ name: 'Vesterstien', completed: false },
{ name: 'Vesteråsveien', completed: false },
{ name: 'Vestfoldgata', completed: false },
{ name: 'Vestgrensa', completed: false },
{ name: 'Vestheimgata', completed: false },
{ name: 'Vestlia', completed: false },
{ name: 'Vestlisvingen', completed: false },
{ name: 'Vestliveien', completed: false },
{ name: 'Vestre Elvebakke', completed: false },
{ name: 'Vestre Haugen', completed: false },
{ name: 'Vestre Holmen vei', completed: false },
{ name: 'Vestveien', completed: false },
{ name: 'Vetlandsfaret', completed: false },
{ name: 'Vetlandsveien', completed: false },
{ name: 'Vettaliveien', completed: false },
{ name: 'Via Nova', completed: false },
{ name: 'Vibes gate', completed: false },
{ name: 'Victoria terrasse', completed: false },
{ name: 'Vidars gate', completed: false },
{ name: 'Vidjeveien', completed: false },
{ name: 'Vidsynveien', completed: true },
{ name: 'Viggo Hansteens vei', completed: false },
{ name: 'Vikengata', completed: false },
{ name: 'Vikingveien', completed: false },
{ name: 'Vilberggrenda', completed: false },
{ name: 'Vilbergveien', completed: false },
{ name: 'Villaveien', completed: false },
{ name: 'Vindernveien', completed: false },
{ name: 'Vingolfveien', completed: false },
{ name: 'Vinkelplassen', completed: false },
{ name: 'Vinkelveien', completed: false },
{ name: 'Vinterveien', completed: false },
{ name: 'Vitaminveien', completed: true },
{ name: 'Vogts gate', completed: true },
{ name: 'Voksenhagen', completed: false },
{ name: 'Voksenkollveien', completed: false },
{ name: 'Voksenliveien', completed: false },
{ name: 'Vollaveien', completed: false },
{ name: 'Vollebekkveien', completed: false },
{ name: 'Volvat terrasse', completed: false },
{ name: 'Von der Lippes gate', completed: false },
{ name: 'Von Øtkens vei', completed: false },
{ name: 'Vossegata', completed: true },
{ name: 'Vulkan', completed: false },
{ name: 'Vækerø terrasse', completed: false },
{ name: 'Vækerøveien', completed: false },
{ name: 'Vækerøåsen', completed: false },
{ name: 'Væringkleiva', completed: false },
{ name: 'Vørterhusplassen', completed: false },
{ name: 'Vøyensvingen', completed: false },
{ name: 'Vågebyveien', completed: true },
{ name: 'Våghalsen', completed: false },
{ name: 'Vålerenggata', completed: false },
{ name: 'Vålveien', completed: false },
{ name: 'Våronnveien', completed: false },
{ name: 'Vårsvingen', completed: false },
{ name: 'Vårveien', completed: false },
{ name: 'Waldemars hage', completed: false },
{ name: 'Waldemar Thranes gate', completed: false },
{ name: 'Wedels vei', completed: false },
{ name: 'Welding Olsens vei', completed: false },
{ name: 'Welhavens gate', completed: false },
{ name: 'Wergelandsveien', completed: false },
{ name: 'Wessels gate', completed: false },
{ name: 'Wessels plass', completed: false },
{ name: 'Westye Egebergs gate', completed: false },
{ name: 'Wetlesens vei', completed: false },
{ name: 'Wildenveybakken', completed: false },
{ name: 'Wilhelm Færdens vei', completed: false },
{ name: 'Wilhelm Stenersens vei', completed: false },
{ name: 'Wilhelms gate', completed: false },
{ name: 'Wilses gate', completed: false },
{ name: 'Wilsters vei', completed: false },
{ name: 'Wismargata', completed: false },
{ name: 'Wolffs gate', completed: false },
{ name: 'Wyllerveien', completed: false },
{ name: 'Ymers vei', completed: false },
{ name: 'Youngs gate', completed: false },
{ name: 'Youngstorget', completed: false },
{ name: 'Zahlkasserer Schafts plass', completed: false },
{ name: 'Zetlitz gate', completed: false },
{ name: 'Zinoberstubben', completed: false },
{ name: 'Zinoberveien', completed: false },
{ name: 'Ødegårdsveien', completed: false },
{ name: 'Øgårdsveien', completed: false },
{ name: 'Øivinds vei', completed: false },
{ name: 'Økern torgvei', completed: true },
{ name: 'Økernliveien', completed: false },
{ name: 'Økernveien', completed: true },
{ name: 'Ørakerfaret', completed: false },
{ name: 'Ørakerstien', completed: false },
{ name: 'Ørakerveien', completed: false },
{ name: 'Øraveien', completed: false },
{ name: 'Østbakken', completed: false },
{ name: 'Østbyfaret', completed: false },
{ name: 'Østbyveien', completed: false },
{ name: 'Østdalsveien', completed: false },
{ name: 'Østensjø terrasse', completed: false },
{ name: 'Østensjøveien', completed: false },
{ name: 'Østerdalsgata', completed: false },
{ name: 'Østerli terrasse', completed: false },
{ name: 'Østerlisvingen', completed: false },
{ name: 'Østerliveien', completed: false },
{ name: 'Østfoldgata', completed: false },
{ name: 'Østgaards gate', completed: true },
{ name: 'Østhellinga', completed: false },
{ name: 'Østhornveien', completed: false },
{ name: 'Østmarkveien', completed: false },
{ name: 'Østre Aker vei', completed: false },
{ name: 'Østre Elvebakke', completed: false },
{ name: 'Østre Holmensvingen', completed: false },
{ name: 'Østre vei', completed: true },
{ name: 'Østreheimsveien', completed: false },
{ name: 'Østvangveien', completed: false },
{ name: 'Østvollveien', completed: false },
{ name: 'Øverliveien', completed: false },
{ name: 'Øvre gate', completed: false },
{ name: 'Øvre Langås vei', completed: false },
{ name: 'Øvre Ljanskoll vei', completed: false },
{ name: 'Øvre Lunden', completed: false },
{ name: 'Øvre Myrhaugen', completed: false },
{ name: 'Øvre Prinsdals vei', completed: false },
{ name: 'Øvre Skjoldvei', completed: false },
{ name: 'Øvre Skogvei', completed: false },
{ name: 'Øvre Slottsgate', completed: false },
{ name: 'Øvre Smestadvei', completed: false },
{ name: 'Øvre Stabburvei', completed: false },
{ name: 'Øvre Ullern terrasse', completed: false },
{ name: 'Øvre vaskegang', completed: false },
{ name: 'Øvre Vollgate', completed: false },
{ name: 'Øvrefoss', completed: false },
{ name: 'Øvreseterveien', completed: false },
{ name: 'Øysteins plass', completed: true },
{ name: 'Aagots vei', completed: false },
{ name: 'Åkebergveien', completed: false },
{ name: 'Åkerøveien', completed: false },
{ name: 'Ålesundgata', completed: false },
{ name: 'Ålmoveien', completed: false },
{ name: 'Ålovs vei', completed: true },
{ name: 'Åmot grustak', completed: false },
{ name: 'Åmotveien', completed: false },
{ name: 'Årrundkroken', completed: false },
{ name: 'Årrundveien', completed: false },
{ name: 'Årvollia', completed: false },
{ name: 'Årvollveien', completed: false },
{ name: 'Åsaveien', completed: false },
{ name: 'Åsbakken', completed: false },
{ name: 'Åsbrekka', completed: false },
{ name: 'Åsbråten', completed: false },
{ name: 'Åsbråtstien', completed: false },
{ name: 'Åsdalsveien', completed: false },
{ name: 'Åsengata', completed: true },
{ name: 'Åsensvingen', completed: false },
{ name: 'Åsjordet', completed: false },
{ name: 'Åslandhellinga', completed: false },
{ name: 'Åslandkroken', completed: false },
{ name: 'Åslandveien', completed: false },
{ name: 'Åsliveien', completed: false },
{ name: 'Aasmund Vinjes vei', completed: false },
{ name: 'Åsryggen', completed: false },
{ name: 'Åssiden terrasse', completed: false },
{ name: 'Åsstubben', completed: false },
{ name: 'Aasta Hansteens vei', completed: false }
]

export default roads;